export const error = {
   PARTICIPANT_STORE_FAIL: { summary: 'Fehler', detail: 'nicht gespeicherte Teilnehmer' },
   MEALPLAN_STORE_FAIL: { summary: 'Fehler', detail: 'essensplan nicht beantragt' }
};

export const message = {
   PARTICIPANT_STORE_SUCCESS: { summary: 'Erfolg', detail: 'Gespeicherte Teilnehmer' },
   MEALPAN_STORE_SUCCESS: { summary: 'Vielen Dank', detail: 'Verpflegungswunsch an DB übermittelt' }
};


