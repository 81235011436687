<template>
   <table class="b-meal-plan-table">
      <tr>
         <th></th>
         <th v-for="(date,index) in tripDays" :key="index"> {{ date }} </th>
         <th class="b-meal-plan-table__header-price-clarfication">
            {{ $t('price_per_person_per_day') }}
         </th>
      </tr>

      <tr
         v-for="(meal,index) in mealPlans" :key="index"
         :class="{
            'is-disabled' : meal.disabled == true,
            'is-row-pending' : meal.status == 'pending'
         }"
      >
         <td class="b-meal-plan-table__plan-name"> {{ meal.name }} </td>

         <td v-for="(day, index) in tripDays" :key="index">
            <input
               :date="day"
               :meal="meal.id"
               @change="onMealChecked"
               :disabled="mealPlanSelectionIsDisabled(meal, day, index)"
               :id="`day-checkbox-${day}-${meal.id}`"
               :checked="meal.serviceDates.includes(day)"
               class="h-css-checkox"
               type="checkbox"
            />
            <label :for="`day-checkbox-${day}-${meal.id}`"></label>
         </td>

         <td class="b-meal-plan-table__clarfication-wrap">
            <div
               v-html="priceLabel(meal)"
               class="b-meal-plan-table__price"
            >
            </div>

            <div
               v-if="meal.status == 'pending' || meal.status == 'request'"
               class="b-meal-plan-table__status"
               :class="{
                  'is-pending' : meal.status == 'pending',
                  'is-request' : meal.status == 'request'
               }"
            >
               {{ $t(meal.status) }}
            </div>
         </td>
      </tr>
   </table>
</template>

<script>
   export default {
      props: {
         disabled: {
            type: Boolean,
            default: false,
         },
         mealPlansProp: null,
         tripDays: null,
      },
      data() {
         return {
             mealPlans: [],
         }
      },
      methods: {
         mealPlanSelectionIsDisabled(mealPlan, date, index) {
             let isLastDay = index == this.tripDays.length -1;
             let isFirstDay = index == 0;
             return  this.disabled ||
                 mealPlan.disabled ||
                 mealPlan.serviceDates.includes(date) ||
                 ( isLastDay && !mealPlan.availableOnLastDay) ||
                 ( isFirstDay && mealPlan.disabledOnFirstDay);
         },
         onMealChecked(event) {

             let selected = event.srcElement.checked;
             let meal = event.srcElement.getAttribute('meal');
             let date = event.srcElement.getAttribute('date');

             if (selected) {
                 this.$emit('addMealToRequest', meal, date);
                 return;
             }

             this.$emit('removeMealFromRequest', meal, date);
         },
         priceLabel(meal) {
             if (meal.isIncluded) {
                 return this.$t('also_includes');
             }

             if (meal.isUponRequest) {
                 return this.$t('price_upon_request');
             }

             return `${meal.price} &euro;`;
         },
      },
      created() {
         this.mealPlans = this.mealPlansProp
            .map(meal => JSON.parse(JSON.stringify(meal)));
      }
    };
</script>

<style lang="less">
   .b-meal-plan-table {
       th {
           font-size: calc((14 / var(--base-value)) * 1rem);
           text-align: center;
           .h-font-light;
       }

       td {
           padding: 8px 15px;
       }

       tr.is-row-pending {
           background-color: hsl(var(--color-red-hs) 90%);
       }

       tr.is-disabled {
           // TODO @gpr: check if something needs to be updated here after adding primevue checkbox's
       }

       &__header-price-clarfication {
           font-size: calc((14 / var(--base-value)) * 1rem);
           color: var(--color-gray-3);
           padding-left: 10px;
       }

       &__plan-name {
           margin-right: 10px;
           .h-font-bold;
       }

       &__clarfication-wrap {
           .h-flex-row-left-center;
       }

       &__status {
           margin-left: 15px;
           .flex-shrink(0);

           &.is-pending {
               background-color: hsl(var(--color-red-hs) 70%);
               padding: 1px 7px;
               border-radius: 5px;
               color: white;
           }

           &.is-request {
               color: var(--color-red);
           }
       }
   }
</style>