<template>
   <modal 
      :width="2"
      :visible="isVisible" 
      @closeModal="setRequestNewOfferModalVisibility(false)"
   >
      <template v-slot:header>
         {{ $t('offer_already_expired_modal_title') }}
      </template>

      <template v-slot:body>

         {{ $t('offer_already_expired_modal_description') }}

         <Textarea
            v-model="comment"
            :autoResize="true"
            class="form-control"
            :placeholder="$t('offer_already_expired_modal_input_placeholder')"
         />

         <VueElementLoading :active="isLoading" spinner="bar-fade-scale"/>
      </template>

      <template v-slot:footer>
         <Button
            @click="onSendClick"
            :label="$t('send_request')"
            :disabled="!comment"
         />
      </template>
   </modal>
</template>

<script>
   import Textarea from 'primevue/textarea';
   import Modal from '@/components/shared/Modal.vue';
   import VueElementLoading from 'vue-element-loading';
   import { mapState, mapActions, mapMutations } from 'vuex';

   export default {
      components: {
         Modal,
         Textarea,
         VueElementLoading,
      },
       data() {
         return {
            comment: '',
            loading: false,
         };
      },
      computed: {
         ...mapState({
            isVisible: state => state.dashboard.showRequestNewOfferModal,
            isLoading: state => state.loading.requestNewOfferModal,
         }),
      },
      methods: {
         ...mapMutations([
            'setRequestNewOfferModalVisibility',
         ]),
         ...mapActions([
            'requestNewOffer',
         ]),
         onSendClick() {
            this.requestNewOffer({ comment: this.comment }).then(() => {
               this.setRequestNewOfferModalVisibility(false);
               this.comment = '';
            });
         },
      },
   };
</script>