import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';
import messages from './translations/translations.js';


//https://primefaces.org/primevue
import Toast from "primevue/toast";
import Button from 'primevue/button';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/md-light-indigo/theme.css'; //theme
import 'primevue/resources/primevue.min.css';                //core css
import 'primeicons/primeicons.css';                         //icons

const locale = process.env.NODE_ENV == "development" ? "en" : "de";

const i18n = createI18n({
  locale,
  fallbackLocale: 'de',
  messages
});

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.use(store);
app.use(router);
app.use(i18n);

app.component("Toast", Toast);
app.component("Button", Button);

app.mount('#app');
