<template>
   <SummaryItem icon="profile">
      <div>
         <div class="b-summary-item__main-text">
            {{ $t("participants") }}: {{ groupStructure.total }}
         </div>

         <div class="b-summary-item__light-text" v-html="groupStructureText"></div>
      </div>
   </SummaryItem>
</template>

<script>
   import { mapState } from 'vuex';
   import SummaryItem from '@/components/shared/SummaryItem'

   export default {
      components: { SummaryItem },
      computed: {
         ...mapState([
            'isGroupTrip',
            'groupStructure',
         ]),
         groupStructureText() {
            let adults = this.$t('adults');
            let kids = this.$t('kids');
            let structureText = '';

            if (!this.isGroupTrip) {
               adults = this.$t('teachers');
               kids = this.$t('students');
            }

            structureText += `${adults}: `;

            if (this.groupStructure.adultsMale) {
               structureText += `${ this.groupStructure.adultsMale } ${ this.$t('male_short') }`;
            }

            if (this.groupStructure.adultsMale && this.groupStructure.adultsFemale) {
               structureText += ' - ';
            }

            if (this.groupStructure.adultsFemale) {
               structureText += `${this.groupStructure.adultsFemale} ${this.$t('female_short')}`;
            }

            if (this.groupStructure.kidsTotal !== 0) {
               structureText += `<br>${kids}: `;

               if(this.groupStructure.kidsMale) {
                  structureText += `${this.groupStructure.kidsMale} ${this.$t('male_short')}`;
               }

               if (this.groupStructure.kidsMale && this.groupStructure.kidsFemale) {
                  structureText += ' - ';
               }

               if(this.groupStructure.kidsFemale) {
                  structureText += `${this.groupStructure.kidsFemale} ${this.$t('female_short')}`;
               }
            }

            return structureText;
         },
      },
   }
</script>