import api from './api.js';

function fetchMealPlanData() {
   return api.get('rds/mealplans');
}

function sendRequest(payload) {
   return api.post('rds/mealplans', payload);
}

export default { fetchMealPlanData, sendRequest };