<template>
   <SummaryItem icon="calendar">
      <div class="b-summary-trip-dates">
         <div class="b-summary-item__light-text">
            {{ departureDate.weekDay }}
         </div>

         <div class="b-summary-item__main-text"> {{ departureDate.dayAndMonth }}. </div>
         <div class="b-summary-item__light-text">{{ departureDate.year }}</div>
         <div class="h-arrow"></div>

         <div class="b-summary-item__light-text">
            {{ returnDate.weekDay }}
         </div>

         <div class="b-summary-item__main-text"> {{ returnDate.dayAndMonth }}. </div>
         {{ returnDate.year }}
      </div>
   </SummaryItem>
</template>

<script>
   import { mapState } from 'vuex';
   import SummaryItem from '@/components/shared/SummaryItem'

   export default {
      components: { SummaryItem },
      computed: {
         ...mapState([
            'departureDate',
            'returnDate'
         ]),
      }
   }
</script>

<style lang="less">
   .b-summary-trip-dates {
      .h-flex-row-left-center;

      .b-summary-item__light-text {
         margin-right: 7px;
      }
   }
</style>