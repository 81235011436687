<template>
   <template v-if="onDashboardPage">
      <a
         @click="goToPrintView"
         target="_blank"
         class="b-print-overview-btn"
      >
         <span class="b-print-overview-btn__text">
            {{ $t('print_trip_overview') }}
         </span>
      </a>
   </template>
</template>

<script>
   import navigation from '@/mixins/navigation.js';

   export default {
      mixins: [ navigation ],
   }
</script>

<style lang="less">
   .b-print-overview-btn {
      font-size: var(--base);
      color: var(--color-red);
      cursor: pointer;
      .h-flex-row-left-center;
      .icon(printer);

      &:before {
         background-color: var(--color-red);
         padding: 5px;
         line-height: 1;
         margin-right: 6px;
         color: white;
         font-size: calc((20 / var(--base-value)) * 1rem);
         border-radius: 3px;
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         &:before {
            font-size: calc((16 / var(--base-value)) * 1rem);
         }

         &__text {
            display: none;
         }
      }
   }
</style>