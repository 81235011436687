<template>
   <div class="b-side-menu" :class="{ 'is-menu-mobile-visible' : isMenuMobileVisible }">
      <div
         class="b-side-menu__toggle"
         @click="toggleMobileMenu()"
      >
      </div>

      <div class="b-side-menu__inner">
         <div
            @click="goToDashboardSection"
            v-if="!participantListOnly"
            class="b-side-menu__item"
         >
            <div class="b-side-menu__item-text"> 
               {{ $t("dashboard") }} 
            </div>

            <div
               class="b-side-menu__item-icon"
               :class="[onDashboardPage ? 'icon__dashboard-selected' : 'icon__dashboard']"
            >
            </div>
         </div>

         <div
            @click="goToParticipantListSection"
            v-if="showSensitiveData"
            class="b-side-menu__item"
         >
            <div class="b-side-menu__item-text"> 
               {{ $t("participants_list") }} 
            </div>

            <div
               class="b-side-menu__item-icon"
               :class="[onParticipantListPage ? 'icon__participants-selected' : 'icon__participants']"
            >
               <div v-if="incompleteParticipantsCount > 0" class="h-badge">
                  {{ incompleteParticipantsCount }}
               </div>
               <div v-if="incompleteParticipantsCount < 0" class="h-badge">
                  !
               </div>
            </div>
         </div>

         <div
            @click="goToTripDetailsSection"
            v-if="!participantListOnly"
            class="b-side-menu__item"
         >
            <div class="b-side-menu__item-text"> 
               {{ $t("details") }} 
            </div>

            <div
               class="b-side-menu__item-icon"
               :class="[onTripDetailsPage ? 'icon__luggage' : 'icon__luggage-outline']"
            >
            </div>
         </div>

         <div
            @click="goToCommunicationSection"
            v-if="showCommunicationSection && hasWriteAccess && !participantListOnly"
            class="b-side-menu__item"
         >
            <div class="b-side-menu__item-text"> {{ $t("communication") }} </div>

            <div
               class="b-side-menu__item-icon"
               :class="[onCommunicationPage ? 'icon__communication-selected' : 'icon__communication']"
            >
               <div v-if="unreadMessagesCount" class="h-badge">
                  {{ unreadMessagesCount }}
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapState, mapGetters } from 'vuex';
   import navigation from '@/mixins/navigation.js';

   export default {
      data() {
         return {
            isMenuMobileVisible: false,
         }
      },
      mixins: [ navigation ],
      computed: {
         ...mapState({
            participantListOnly: state => state.isPartner,
            hasWriteAccess:  state => state.writeAccessMode,
            showSensitiveData: state => !state.readOnlyNoSensitiveDataMode,
         }),
         ...mapGetters([
            'incompleteParticipantsCount',
            'showCommunicationSection',
            'unreadMessagesCount',
         ]),
      },
      methods: {
         toggleMobileMenu() {
            this.isMenuMobileVisible = !this.isMenuMobileVisible;
         },
      },
   }
</script>

<style lang="less">
   .b-side-menu {
      --menu-item-size: 30px;

      background-color: var(--color-gray);
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: var(--side-menu-width);
      //z-index -> managed in helpers.less (z-index section)
      box-shadow: 3px -8px 8px rgba(0,0,0,0.5);
      .transition;

       &__toggle {
           font-size: calc((23 / var(--base-value)) * 1rem);
           position: fixed;
           bottom: 15px;
           right: 15px;
           padding: 13px;
           background-color: var(--color-red);
           color: white;
           border-radius: 100%;
           cursor: pointer;
           opacity: 0;
           pointer-events: none;
           z-index: 1;
           .h-flex-col-center;
           .icon(menu);
           .transition;

           &:hover,
           &:active,
           &:focus {
               background-color: var(--color-red-hover);
           }

           .is-menu-mobile-visible & {
              .icon(cross);
           }
       }

       &__inner {
           position: fixed;
           .h-flex-col;
           .align-items(flex-start);
       }

       &__item {
           height: var(--side-menu-width);
           position: relative;
           color: white;

           &:hover,
           &:active,
           &:focus {
               color: white;
               text-decoration: none;
           }
       }

       &__item-icon {
           font-size: var(--menu-item-size);
           width: var(--side-menu-width);
           line-height: 1;
           position: relative;
           cursor: pointer;

           &:before { // icon
               display: block;
               text-align: center;
               line-height: var(--side-menu-width) !important;
               background-color: var(--color-gray);
           }
       }

       &__item-text {
           line-height: var(--side-menu-width);
           position: absolute;
           right: 1px;
           top: 0;
           padding: 0 15px;
           background-color: var(--color-gray);
           white-space: nowrap;
           border-radius: 0 5px 5px 0;
           cursor: pointer;
           .transition;
       }

       .h-badge {
           position: absolute;
           top: 0;
           right: 2px;
       }

       .b-side-menu__item:hover {
           .b-side-menu__item-text {
               .transform(translateX(100%));
           }
       }

       &.is-menu-mobile-visible {
           position: fixed;
           left: 0;
           width: auto;
           z-index: 6;

           .b-side-menu__inner {
               position: relative;
           }

           .b-side-menu__item {
               .h-flex-row-left-center;
               .flex-direction(row-reverse);
           }

           .b-side-menu__item-text {
               position: relative;
               right: auto;
               top: auto;
               .transform(translateX(0));
           }

           .b-side-menu__item:hover {
               .b-side-menu__item-text {
                   .transform(translateX(0));
               }
           }
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           left: calc(~"var(--side-menu-width) * -1");

           &__toggle {
               opacity: 1;
               pointer-events: auto;
           }
       }
   }
</style>