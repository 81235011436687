import store from '../store/index.js';
import routeNames from './routeNames.js';
import Dashboard from '../views/Dashboard.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
   {
      path: '/:accessToken?',
      name: routeNames.DASHBOARD,
      component: Dashboard,
      meta: {
         bodyClass: 'p-dashboard',
      },
   },
   {
      path: '/trip-details/:accessToken?',
      name: routeNames.TRIP_DETAILS,
      component: () => import(/* webpackChunkName: "tripDetails" */ '../views/TripDetails.vue'),
      meta: {
         bodyClass: 'p-trip-details',
      },
   },
   {
      path: '/participant-list/:accessToken?',
      name: routeNames.PARTICIPANT_LIST,
      component: () => import(/* webpackChunkName: "participantList" */ '../views/ParticipantList.vue'),
      meta: {
         bodyClass: 'p-participant-list',
      },
   },
   {
      path: '/communication/:accessToken?',
      name: routeNames.COMMUNICATION,
      component: () => import(/* webpackChunkName: "communication" */ '../views/Communication.vue'),
      meta: {
         bodyClass: 'p-communication',
      },
   },
   {
      path: '/offer/:accessToken?',
      name: routeNames.OFFER,
      component: () => import(/* webpackChunkName: "offer" */ '../views/Offer.vue')
   },
   {
      path: '/print/:accessToken?',
      name: routeNames.PRINT_VIEW,
      component: () => import(/* webpackChunkName: "printView" */ '../views/PrintView.vue'),
      meta: {
         bodyClass: 'p-print-view',
      },
   },
   {
      path: '/participants-print/:accessToken?',
      name: routeNames.PARTICIPANTS_PRINT,
      component: () => import(/* webpackChunkName: "ParticipantsPrint" */ '../views/ParticipantsPrint.vue'),
      meta: {
         bodyClass: 'p-participant-print-view',
      },
   },
]

const router = createRouter({
   history: createWebHistory(),
   routes,
   scrollBehavior(to, from, savedPosition) {
      // be quiet lint
      to; from; savedPosition;
      // always scroll to top
      return { top: 0 }
   },
});

router.beforeEach((to, from, next) => {
   /*if !to.params.accessToken
      redirect to unauthorized page
      set access granted false
      set unauthorized true*/

   let accessToken = store.getters.accessToken;
   let isFirstLoadOrReload = !accessToken;

   if (to.params.accessToken != accessToken) {
      store.commit('setAccessToken', to.params.accessToken);
   }

   if (isFirstLoadOrReload) {

      if (to.name == routeNames.PARTICIPANTS_PRINT) {
         store.dispatch('fetchParticipantPrintData');
         next();
         return;
      }

      store.dispatch('fetchData');
   }

   next();
});

export default router;
