<template>
   <Modal
      :width="2"
      :visible="isVisible"
      v-if="!noSensitiveDataModeEnabled"
      @closeModal="setPendingOfferWarningModalVisibility(false)"
   >
      <template v-slot:header> {{ $t("you_have_a_pending_offer") }} </template>

         <template v-slot:body>
            <div>
               {{ $t("add_program_and_reject_pending_offer_modal_text") }}
            </div>
         </template>

         <template v-slot:footer>
            <a @click="setPendingOfferWarningModalVisibility(false)" class="h-link h-cancel">
               {{ $t("cancel") }}
            </a>
            <a @click="onContinueClick" class="h-button">
               {{ $t("continue_and_reject_existing_offer") }}
            </a>
         </template>
   </Modal>
</template>

<script>
   import { mapState, mapMutations } from 'vuex';
   import Modal from '@/components/shared/Modal.vue';

   export default {
      components: {
         Modal,
      },
      computed: {
         ...mapState({
            isVisible: state => state.dashboard.showPendingOfferWarningModal,
            noSensitiveDataModeEnabled: state => state.readOnlyNoSensitiveDataMode,
         }),
      },
      methods: {
         ...mapMutations([
            'setAddProgramModalVisibility',
            'setPendingOfferWarningModalVisibility',
         ]),
         onContinueClick() {
            this.setAddProgramModalVisibility(true);
            this.setPendingOfferWarningModalVisibility(false);
         }
      },
   }
</script>