<template>
   <div class="l-outter">
      <Header/>

      <Toast position="top-center" group="br" />

      <div class="l-page">
         <SideMenu />
         <div>
            <router-view />
            <!-- pageIsLoading -->
            <VueElementLoading :active="pageIsLoading" spinner="bar-fade-scale" class="is-content-page" />
         </div>
      </div>

      <Footer />
   </div>

   <!-- fullPageIsLoading -->
   <VueElementLoading :active="fullPageIsLoading" spinner="bar-fade-scale" class="is-content-page" />
</template>

<script>
   import maps from '@/mixins/googleMaps.js';
   import Footer from '@/components/shared/Footer.vue';
   import VueElementLoading from "vue-element-loading";
   import SideMenu from '@/components/shared/SideMenu.vue';
   import Header from '@/components/shared/header/Header.vue';

   import { mapState, mapMutations } from 'vuex';

   export default {
      name: 'Dashboard',
      mixins: [ maps ],
      components: {
         Header,
         Footer,
         SideMenu,
         VueElementLoading,
      },
      watch: {
         bodyClass(value) {
            document.body.className = '';
            if (!value) {
               return;
            }
            document.body.classList.add(value);
         }
      },
      computed: {
         ...mapState({
            dataHasBeenSet: state => state.dataHasBeenSet,
            fullPageIsLoading: state => state.loading.full,
            isMobile: state => state.IsMobile,
            pageIsLoading: state => state.loading.page,
            hasUnsavedChanges: state => state.participantList.unSavedChanges,
         }),
         bodyClass() {
            return this.$route.meta.bodyClass ? this.$route.meta.bodyClass : '';
         },
      },
      methods: {
         ...mapMutations([
            'setConsent',
            'setToast',
            'setIsMobile',
         ]),
         checkIsMobile() {
            let isMobile;
            if(window.innerWidth < 600){
               isMobile = true;
            } else {
               isMobile = false;
            }
            if(this.isMobile == isMobile){
               return;
            }
            this.setIsMobile(isMobile);
         },
         getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
               let c = ca[i];
               while (c.charAt(0) == ' ') {
                  c = c.substring(1);
               }
               if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
               }
            }
            return undefined;
         }
      },
      mounted() {

         if (!this.dataHasBeenSet) {
            this.setToast(this.$toast);
         }

         this.checkIsMobile();

         let consentCookie = this.getCookie('consent');
         if (consentCookie !== undefined) {
            this.setConsent(consentCookie === 'true');

            if (consentCookie === 'true') {
               this.injectGoogleScriptIntoTheDOM();
            }
         }

         window.addEventListener('resize', this.checkIsMobile);
         window.addEventListener("beforeunload", (e) => {
            if(this.hasUnsavedChanges){
               let confirmationMessage =
                  'It looks like you have been editing something. ' +
                  'If you leave before saving, your changes will be lost.';

                  (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                  return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
               }
         });
      },
      beforeUnmount() {
         window.removeEventListener('resize', this.checkIsMobile)
      }
   }
</script>
