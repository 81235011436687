import api from './api.js';

function store(participants) {
   return api.post('rds/participants', participants);
}

function fetch() {
   return api.get('rds/participants');
}

export default {
   store,
   fetch,
};