<template>
    <div class="b-collapsible" :class="{ 'is-open' : show }">
        <div v-if="showHeader" class="b-collapsible__header" @click="toggle">
            <div class="b-collapsible__trigger">
                <slot name="trigger">
                    <span class="h-link">{{ $t("more_details") }}</span>
                </slot>
            </div>

            <div class="b-collapsible__trigger-icon">
                <slot name="trigger-icon">
                    <i class="b-collapsible__default-trigger-icon">&nbsp;</i>
                </slot>
            </div>
        </div>

        <transition
            name="collapsible"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
        >
            <div class="b-collapsible__content" v-show="show">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            isCollapsed: {
                type: Boolean,
                default: true,
            },
            showHeader: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                show: !this.isCollapsed,
            }
        },
        computed: {
            ...mapState([
                'openMealPlans'
            ])
        },
        methods: {
            toggle: function() {
                this.show = !this.show;
            },

            beforeEnter: function(el) {
                el.style.height = '0';
            },
            enter: function(el) {
                el.style.height = el.scrollHeight + 'px';
                setTimeout(function() {
                    el.style.height = "";
                }, 300);
            },
            beforeLeave: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function(el) {
                el.style.height = '0';
            },
        },
        created() {
            if (this.openMealPlans) {
                this.show = true;
            }
        }
    };
</script>

<style lang="less">
    .b-collapsible {
        &__header {
            cursor: pointer;
            margin-bottom: 10px;
            .h-flex-row-left-center;
        }

        &__default-trigger-icon {
            font-size: calc((15 / var(--base-value)) * 1rem);
            margin-left: 6px;
            line-height: 1;
            position: relative;
            top: 2px;
            color: hsl(var(--color-red-hs) 70%);
            display: block;
            .icon(arrow-down);
            .transition;
        }

        &__content {
            overflow: hidden;
            .transition(all, 0.35s, ease);
        }

        // status
        &.is-open {
            .b-collapsible__default-trigger-icon {
                top: -1px;
                .transform(rotate(180deg));
            }
        }
    }
</style>