<template>
   <div class="b-suggested-programs">
      <div class="b-suggested-programs__title">
         {{ $t("want_more_for_your_trip") }}
      </div>

      <!-- search -->
      <ProgramSearch />

      <!-- add program warning -->
      <WarningMessage
         v-if="!wasBooked"
         icon="exclamation"
         :title="$t('adding_programms_possible_after_booking')"
         :isProgramWarning="true"
         :isCompact="true"
      />

      <div class="b-suggested-programs__wrap" v-if="avaiblePrograms.length">
         <div class="l-flex-grid">
            <ProgramThumb
               v-for="(program, index) in avaiblePrograms.slice(0, displayedProgramCount)" 
               :key="index"
               :program="program"
               class="l-flex-grid__item"
            />
         </div>

         <Button
            v-if="hasHiddenPrograms"
            :label="$t('show_x_more',{ keyword: hiddenProgramsLabel })" 
            @click="onShowMoreClick"
         />
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import Button from 'primevue/button';
   import ProgramThumb from '@/components/dashboard/suggested_programs/ProgramThumb.vue';
   import ProgramSearch from '@/components/dashboard/suggested_programs/ProgramSearch.vue';
   import WarningMessage from '@/components/shared/WarningMessage.vue';

   export default {
      components: {
         Button,
         ProgramThumb,
         ProgramSearch,
         WarningMessage,
      },
      data() {
         return {
            displayedProgramCount: 10,
            hasProgramSearchResults: true,
            hiddenProgramsCount: 0,
            programsPerPage: 10,
         };
      },
      computed: {
         ...mapState({
            programs: state => state.dashboard.filteredPrograms,
            wasBooked: state => state.wasBooked,
         }),
         avaiblePrograms() {
            return  this.programs.filter(program => program.isAvailable == true);
         },
         hasHiddenPrograms() {
            return this.hiddenProgramsCount > 0;
         },
         hiddenProgramsLabel() {
            return this.hiddenProgramsCount >= this.programsPerPage
               ? this.programsPerPage
               : this.hiddenProgramsCount;
         },
      },
      methods: {
         onShowMoreClick() {
            this.displayedProgramCount += this.programsPerPage;

            if (this.hiddenProgramsCount < this.programsPerPage) {
               this.hiddenProgramsCount = 0;
               return;
            }
            
            this.hiddenProgramsCount -= this.programsPerPage;
         }
      },
      watch: {
         programs() {
            this.displayedProgramCount = this.programsPerPage;
            this.hiddenProgramsCount = this.avaiblePrograms.length - this.programsPerPage;
         }
      },
      created() {
         this.hiddenProgramsCount = this.avaiblePrograms.length - this.programsPerPage;
      },
   }
</script>

<style lang="less">
   .b-suggested-programs {
      &__title {
         font-size: 17px;
         margin-bottom: 10px;
         .h-flex-row-left-center;
         .icon(camera);

         &:before {
            font-size: calc((23 / var(--base-value)) * 1rem);
            color: var(--color-gray-3);
            margin-right: 10px;
         }
      }

      &__wrap {
         margin-top: 5px;
      }

      .b-warning-message {
         .h-flex-row-left-center;
         margin-bottom: 15px;
      }
   }
</style>