<template>
   <div class="b-timeline">

      <LocationTimelineItem v-for="item in locationItems" :locationItem="item" :key="item.name + item.date">
         {{ item.name }}
         <span v-if="!item.isStartingPoint" class="b-timeline-item__date">
            {{ item.formattedDate }}
         </span>
      </LocationTimelineItem>

      <a class="b-timeline__details-link" @click="goToTripDetailsSection">
         {{ $t("go_to_trip_details") }}
      </a>

   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import navigation from '@/mixins/navigation.js';
   import LocationTimelineItem from '@/components/dashboard/timeline/LocationTimelineItem.vue';

   export default {
      components: {
         LocationTimelineItem
      },
      mixins: [ navigation ],
      computed: {
         ...mapState({
            locationItems: state => state.dashboard.timeline,
         }),
      },
   };
</script>

<style lang="less">
   .b-timeline {
      &__details-link {
         display: inline-block;
         margin-top: 40px;
         .h-link; 
      }
   }
</style>