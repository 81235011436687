<template>
   <div class="b-trip-summary">
      <TripDates />
      <TripDestinations />
      <PaxInfo />
   </div>

   <TopTexts />
</template>

<script>
   import TopTexts from '@/components/dashboard/trip_summary/TopTexts';
   import PaxInfo from '@/components/dashboard/trip_summary/PaxInfo.vue';
   import TripDates from '@/components/dashboard/trip_summary/TripDates.vue';
   import TripDestinations from '@/components/dashboard/trip_summary/TripDestinations.vue';

   export default {
      components: {
         PaxInfo,
         TopTexts,
         TripDates,
         TripDestinations,
      },
   };
</script>

<style lang="less">
   .b-trip-summary {
      .h-trip-summary;

      @media only screen and (max-width : @breakpoint-md) {
         .h-flex-col;
         .align-items(flex-start);

         .b-summary-item {
            margin-bottom: 10px;
         }
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         .b-summary-item {
            margin-bottom: 7px;
         }
      }
   }
</style>