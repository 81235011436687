<template>
   <div class="b-program-thumb">
      <img class="b-program-thumb__image" :src="program.image" :alt="program.title">

      <div class="b-program-thumb__content">
         <div class="b-program-thumb__left">
            <div class="b-program-thumb__title" @click="onInfoIconClick"> {{ program.title }} </div>

            <a class="b-program-thumb__info-modal" @click="onInfoIconClick"></a>

            <div class="b-program-thumb__price-type"> {{ priceType }} </div>

            <!-- extras -->
            <div v-if="hasExtras" class="b-program-thumb__extras">
               <div class="b-program-thumb__extras-header">
                  {{ $t('available_extras') }}
               </div>
            </div>
         </div>


         <div class="b-program-thumb__right">
            <div v-if="program.availableUponRequest" class="b-program-thumb__price-upon-request-text">
               {{ $t('price_upon_request') }}
            </div>

            <div v-else class="b-program-thumb__price">
               <span
                  v-if="program.isFromPrice"
                  class="b-program-thumb__price-type"
                  style="margin-right:5px;"
               >
                  {{ $t('from_price') }}
               </span> € {{ program.price }}
            </div>

            <Button :label="$t('add')" @click="onAddClick" v-if="wasBooked" />
         </div>
      </div>
   </div>
</template>

<script>
   import { mapState, mapMutations, mapActions } from 'vuex';
   import { tripStatusName as status } from '@/constants/global.js';
   import Button from 'primevue/button';

   export default {
      components: {
         Button
      },
      props: {
         program: {
            type: Object,
            required: true,
         },
      },
      data(){
         return {
            priceType: this.program.price_type,// camelCase it on the backend and delete this
         }
      },
      computed:{
         ...mapState({
            tripStatus: state => state.tripStatus,
            wasBooked: state => state.wasBooked,

         }),
         hasExtras() {
            return !!this.program.extras.length;
         },
      },
      methods: {
         ...mapMutations([
            'setAddProgramModalVisibility',
            'setPendingOfferWarningModalVisibility',
            'setProgramInfoModalVisibility',
            'setSelectedProgram',
         ]),
         ...mapActions([
            'displayProgramInfo',
         ]),
         onAddClick() {
            
            this.setSelectedProgram(this.program);

            if (this.tripStatus == status.OFFER_AWAITING) {
               this.setPendingOfferWarningModalVisibility(true);
               return;
            }


            this.setAddProgramModalVisibility(true);
         },
         onInfoIconClick() {
            this.setSelectedProgram(this.program);
            this.setProgramInfoModalVisibility(true);
         },
      },
    };
</script>

<style lang="less">
   .b-program-thumb {
      background-color: white;
      min-height: 96px;
      width: 100%;
      .h-shadow;
      .h-flex-row-left-center;
      .align-items(stretch);

      &__image {
         width: 150px;
         object-fit: cover;
         .flex-shrink(0);
      }

      &__content {
         padding: 15px 20px;
         .flex-grow(1);
         .h-flex-row-left-center;
      }

      &__left {
         .flex-grow(1);
      }

      &__title {
         .h3-style;
         cursor: pointer;
         display: inline;
      }

      &__info-modal {
         font-size: calc((18 / var(--base-value)) * 1rem);
         color: var(--color-gray-3);
         margin-left: 5px;
         position: relative;
         top: 2px;
         text-decoration: none;
         .icon(info);
         .transition;

         &:hover {
            color: var(--color-red);
         }
         cursor: pointer;
      }

      &__right {
         margin-left: 30px;
         text-align: right;
         .flex-shrink(0);
      }

      &__price-upon-request-text {
         color: var(--color-gray-2);
         margin-bottom: 5px;
      }

      &__price {
         font-size: calc((19 / var(--base-value)) * 1rem);
         color: var(--color-gray);
         margin-bottom: 7px;
         .h-font-bold;
      }

      &__price-type {
         color: var(--color-gray-3);
         font-size: var(--base);
         line-height: 1;
         .h-font-light;
      }

      &__extras-header {
         margin-top: 10px;
      }

      &__extra-name {
         margin-top: 4px;
         .h-font-regular;
         .h-flex-row-left-top;

         &:before {
            content: '';
            width: 5px;
            height: 5px;
            margin-top: 8px;
            background-color: var(--color-ok);
            border-radius: 100%;
            margin-right: 7px;
            .flex-shrink(0)
         }
      }

      .h-button {
         opacity: 0.60;
         .transition;
      }

      &:hover {
         .p-button:not(:disabled) {
            opacity: 1;
         }
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         .h-flex-col;

         &__image {
            width: 100%;
            height: 150px;
         }

         &__content {
            .h-flex-col;
         }

         &__title {
            text-align: center;
         }

         &__right {
            margin-top: 20px;
            margin-left: 0;
            .h-flex-row-left-center;
         }

         &__price-wrap {
            margin-right: 10px;
         }

         .p-button:not(:disabled) {
            margin-left: 10px !important;
         }
      }
   }
</style>