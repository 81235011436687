<template>
   <Modal
      class="b-trip-status-guide-modal"
      :visible="isModalVisible"
      @closeModal="closeGuideModal"
      :width="2"
   >
      <template v-slot:header> {{ $t('status_guide') }} </template>

         <template v-slot:body>
            <TripStatusGuideItem
               v-if="statusInfo && !statusInfo.inGuideStatusList"
               :status="statusInfo"
               class="h-margin-bottom-40 is-active"
            />

           <TripStatusGuideItem
               v-for="(statusItem, index) in tripStatusArray"
               :show="statusItem.inGuideStatusList"
               :status="statusItem"
               :key="index"
               :hasLine="true"
               :tripStatusClass="tripStatusClass"
           />
       </template>

       <template v-slot:footer>
           <span
               class="h-button"
               @click="closeGuideModal"
           >
             {{ $t("ok") }}
           </span>
       </template>
   </Modal>
</template>

<script>
   import { mapState } from 'vuex';
   import Modal from '@/components/shared/Modal.vue';
   import tripStatuses from '@/constants/tripStatuses';
   import TripStatusGuideItem from '@/components/shared/header/status/TripStatusGuideItem.vue';

   export default {
      components: {
         Modal,
         TripStatusGuideItem
      },
      props: {
         isModalVisible: {
            type: Boolean,
            default: false,
         },
         tripStatusClass: {
            type: String
         }
      },
      computed: {
         ...mapState([
            'tripStatus'
         ]),
         statusInfo() {
            return tripStatuses[this.tripStatus];
         },
         tripStatusArray() {
            let statusArray = [];
            for(const tripStatus in tripStatuses) {
               statusArray.push(tripStatuses[tripStatus]);
            }
            return statusArray;
         },
      },
      methods: {
         closeGuideModal() {
            this.$emit('close');
         },
      }
   }
</script>