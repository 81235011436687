import tripDetailsService from '../../services/tripDetails.js';

export default {

   state: () => ({
      extras: [],
      programs: [],
      accommodations: [],
      transportation: [],
   }),

   getters: {
      accommodationNotes: state => state.accommodations.length
         ? state.accommodations[0].notes
         : null,
   },

   actions: {
      fetchTripDetailsData({ commit }) {
         return tripDetailsService.fetchData()
            .then(data => commit('setTripDetailsData', data))
            .catch(error => console.log(error));
      },
   },

   mutations: {
      setTripDetailsData(state, data) {
         state.extras  = data.extras ? [...data.extras] : [];
         state.programs  = data.programs ? [...data.programs] : [];
         state.accommodations  = data.accommodations ? [...data.accommodations] : [];
         state.transportation  = data.transports ? [...data.transports] : [];
      },
   },

}