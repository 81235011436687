<template>
   <transition name="modal">
      <div v-show="visible" class="b-modal" :class="sizeClass">
         <div class="b-modal__wrapper">
            <div class="b-modal__container">
               <div class="b-modal__header">
                  <!-- close btn -->
                  <span @click="close" class="b-modal__close"></span>
                  <!-- header -->
                  <slot name="header"></slot>
               </div>

               <div class="b-modal__body">
                  <slot name="body"></slot>
                  <slot name="textarea"></slot>
               </div>

               <div class="b-modal__footer">
                  <slot name="footer">
                     <span @click="close()" class="h-button">
                        {{ $t("ok") }}
                     </span>
                  </slot>
               </div>

               <VueElementLoading
                  :active="loading"
                  background-color="#fff"
                  spinner="bar-fade-scale"
                  color="#f00f0f"
               />
            </div>
         </div>
      </div>
   </transition>
</template>

<script>
   import VueElementLoading from 'vue-element-loading';

   export default {
      components: {
         VueElementLoading
      },
      props: {
         width: {
             type: Number,
             default: 1, // 1 small, 2 medium, 3 big
         },
         loading: {
             type: Boolean,
             default: false,
         },
         visible: {
             type: Boolean,
             default: false,
         }
      },
      computed: {
         sizeClass() {
            return {
               'is-big' : this.width == 3,
               'is-small' : this.width == 1,
               'is-medium' : this.width == 2,
               'is-full-width' : this.width == 4
            };
         }
      },
      methods: {
         close() {
             this.$emit('closeModal');
         },
      },
   };
</script>

<style lang="less">
   .b-modal {
       --modal-padding: 20px;
       --top-padding: 50px;

       position: fixed;
       //z-index -> managed in helpers.less (z-index section)
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       width: 100%;
       height: 100%;
       background-color: rgba(0, 0, 0, .5);
       display: table;
       transition: opacity .3s ease;

       &__wrapper {
           display: table-cell;
           vertical-align: top;
       }

       &__container {
           margin: var(--top-padding) auto 0 auto;
           padding: 0;
           background-color: var(--color-gray-light);
           border-radius: 2px;
           box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
           .transition;
       }

       &__header {
           padding: 16px 50px 13px var(--modal-padding);
           background-color: white;
           line-height: 1;
           position: relative;
           border-bottom: solid 1px var(--color-gray-4);
           .h2-style;
       }

       &__close {
           position: absolute;
           top: 12px;
           right: 12px;
           font-size: 17px;
           color: var(--color-gray-3);
           cursor: pointer;
           .icon(cross);
           .transition;

           &:hover,
           &:active,
           &:focus {
               color: var(--color-gray);
           }
       }

       &__body {
           padding: var(--modal-padding);
           max-height: calc(~"75vh -" var(--top-padding));
           overflow: auto;
       }

       &__footer {
           padding: var(--modal-padding);
           .h-flex-row-right-center;
       }

       &__button {
           line-height: 1;
       }

       // sizes
       &.is-small {
           .b-modal__container {
               width: 400px;
           }
       }

       &.is-medium {
           .b-modal__container {
               width: 600px;
           }
       }

       &.is-big {
           .b-modal__container {
               width: 600px;
           }
       }

       &.is-full-width {
           .b-modal__container {
               width: 90vw;
           }
       }

       &.is-second-modal { // when there is a second modal inside a modal
           z-index: 999999;

           &.is-big {
               .b-modal__container {
                   width: 600px;
               }
           }
       }

       @media only screen and (max-width : @breakpoint-md) {
           &.is-big {
               .b-modal__container {
                   width: 95vw;
               }
           }
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           &.is-small,
           &.is-medium {
               .b-modal__container {
                   width: 95vw;
               }
           }
       }
   }
</style>