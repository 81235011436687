export default {
   account: "Organisation",
   accept_cookies_explaination: "Bitte akzeptieren Sie alle Cookies, um Google Maps nutzen zu können",
   adding_programms_possible_after_booking: 'Das Hinzufügen von Programm, ist nach der Buchung möglich',
   awaiting_confirmation: 'Bestätigung offen',
   close: "Schließen",
   accept_the_use_of_google_maps: 'Cookies zulassen und Karte anzeigen',
   how_to_get_to: 'Lage',
   hs: 'Uhr',
   loading: "loading",
   unauthorized_access: "unbefugter Zugriff",
   trip_code: "Reisecode",
   print_trip_overview: "Infoblatt",
   print_view_footer_text: 'Ihr Team von DB Klassenfahrten & Gruppenreisen wünscht Ihnen eine gute Fahrt und einen angenehmen Aufenthalt.',
   excel_export_tooltip: "export participant list",
   footer_data_privacy: "Datenschutz",
   participants: "Personenanzahl",
   adults: "Erwachsene",
   kids: "Kinder",
   teachers: "Begleiter*innen",
   students: "Lernende",
   is_student: "Lernende",
   db_emergency_services: '24-Stunden-Notruf',
   db_emergency_services_text: 'Sollte Ihnen in einem dringenden Notfall kein Ansprechpartner vor Ort zur Verfügung stehen und Sie außerhalb unserer Geschäftszeiten dringend Unterstützung benötigen, nutzen Sie unsere Notrufnummer unter:',
   db_user_not_assigned_yet: "noch nicht zugeordnet",
   db_offices: 'DB Klassenfahrten und Gruppenreisen',
   db_offices_text: 'Montag bis Freitag von 09:00 - 12:00 Uhr und 14:00 - 17:00 Uhr (zum Ortstarif)',
   trip_type: "Reiseart",
   db_contact_name: "DB Kontakt",
   male_short: "m",
   male: "Männlich",
   female_short: "w",
   female: "Weiblich",
   db_contact_phone: "DB Telefon Kontakt",
   ok: "ok",
   status_guide: "Was bedeuten die Status?",
   process: "Vorgang",
   status_trip_archived: "Vorgang archiviert",
   status_trip_archived_description: "Diese Anfrage wurde archiviert.",
   db_partner_hotline: "+49 (0)721-9385386",
   go_to_trip_details: "zur Reisedetailseite",
   dashboard: "Startseite",
   participants_list: "Teilnahmeliste",
   details: "Details", // not used?
   communication: "Kommunikation",
   participant_list_blocked_by_db: "Die Teilnehmerliste ist durch DB gesperrt",
   not_in_list: "nicht in der Liste",
   bus_drivers: "Busfahrer",
   travellers: "Reisende",
   missing: "Unvollständig",
   too_many_students_stored: "Zu viele Personen",
   too_many_teachers_stored: "Zu viele Personen",
   too_many_travellers_stored: "zu viele Personen",
   teachers_completed: "Begleiter komplett",
   participants_completed: "Teilnehmer/innen komplett",
   travellers_completed: "Reisende komplett",
   first_name: "Vorname",
   last_name: "Nachname",
   gender: "Geschlecht",
   birth: "Geburtsdatum",
   birth_date: "Geburtsdatum",
   participant_nationality: "Nationalit&auml;t",
   nationality: "Nationalit&auml;t",
   select: "auswählen",
   select_date_format: "TT.MM.JJJJ",
   document_type: "Dokumentenart",
   document_number: "Dokumentennummer",
   document_nr: "Dokumentennummer",
   document_country: "Ausstellungsland",
   document_issuing_country_id: 'Ausstellungsland',
   document_expiration: "Ablaufdatum",
   document_expiration_date: "Ablaufdatum",
   issued_on: "Ausstellungsdatum",
   document_issue_date: "Ausstellungsdatum",
   has_allergies: "Allergien",
   allergies: "Allergien und Unvertr&auml;glichkeiten",
   allergies_tooltip_text: 'Allergien und Unverträglichkeiten',
   uncollapse_health: "Angaben zu Allergien und Impfstatus",
   show_health_fields: "Impfstatus/Allergien",
   show_comments_fields: "Kommentar",
   comments: "Kommentare",
   others: "Kommentare",
   has_comments: "Kommentar",
   comments_tooltip_text: "Zusätzliche Angaben",
   participant_comments_field: "Kommentar zur teilnehmenden Person",
   participant_documents_title: "Ausweisdokument",
   other: "Sonstiges",
   passport: "Reisepass",
   gender_other: "Divers",
   show_document_fields: "Ausweisdokument",
   personnel_pass: "Personalausweis",
   clear: "Entfernen",
   issuing_country: "Ausstellungsland",
   trip_dates: 'Reisedatum',
   trip_detail: "Reisedetails",
   trip_timeline: "Ablauf",
   check_offer_for_alternatives: "Zum Anzeigen von Alternativen klicken Sie bitte hier",
   persons_in: "Personen im",
   check_details_in_online_catalog: "Details im Online-Katalog prüfen",
   show_full: "vollständig anzeigen",
   show_less: "weniger anzeigen",
   meal_plans: "Verpflegung",
   meal_plan_not_clickable_text: "Hunger? Die Mahlzeiten können Sie auf Ihrer Startseite hinzufügen.",
   meal_plan_not_clickable_link: "Klicken Sie bitte hier.",
   pending_approval: "warten auf Bestätigung",
   departure_time: "Abfahrtszeit",
   arrival_time: "Ankunftszeit",
   want_to_save_changes: "Wollen Sie die Änderungen speichern?",
   cancel: "abbrechen",
   save: "Speichern",
   pending: "schwebend",
   send: "senden",
   trip_history: "Reisehostorium",
   trip_messages: "Nachrichten",
   trip_communication: "Kommunikation",
   db_trip_responsible: "DB Betreiber",
   awaiting_trip_advisor: "in Erwartung des Reiseberaters",
   enter_your_message: "Ihre Nachricht eingeben",
   no_value: "keine Angabe",
   more_details: "weitere Details",
   customer: "Kunden",
   chat_you: "Sie",
   want_more_for_your_trip: "Übersicht Programme",
   suggestions: "Vorschläge und Tipps",
   no_results_found_for: "keine Ergebnisse gefunden für {keyword}",
   show_x_more: "zeige {keyword} mehr",
   available_extras: "verfügbare Extras",
   price_upon_request: "auf Anfrage",
   from_price: "ab",
   add: "hinzufügen",
   search_for_programs_placeholder: "Suchen",
   add_program: "Programm wählen",
   travellor: "Reisende",
   rds_warning_pax_change_might_affect_price: "abweichender Preis möglich",
   on_the_day: "Wunschtermin",
   time: "Zeit",
   want_to_enter_comment: "Text bitte hier eingeben",
   this_will_also_send_request: "Buchungsanfrage an DB senden!",
   add_to_trip: "Auswählen",
   for: "für",
   price_per_person_per_day: "Preis pro Person/Tag",
   also_includes: "inklusive",
   for_more_info_please_check: "weitere Informationen:",
   important: "Wichtig",
   data_deletion_schedule: "Die Löschung der Angaben erfolgt nach sechs Monaten.",
   aditional_information: "Zusätzliche Informationen",
   class_participant_list_top_message: "Um die Anzahl/Aufteilung der Teilnehmer*innen zu ändern, ist es erforderlich uns eine Email an {email}  zuzusenden.",
   group_participant_list_top_message: "Um die Anzahl/Aufteilung der Teilnehmer*innen zu ändern, ist es erforderlich uns eine Email an {email}  zuzusenden.",
   see_more_details: 'Weitere Informationen',
   status_awaiting_confirmation_from_db: "warten auf Bestätigung durch DB",
   status_offer_booked: "Angebot gebucht",
   status_offer_confirmed: "Buchung bestätigt",
   status_offer_confirmed_description: "Nun ist die Buchung abgeschlossen. Bald kann es losgehen.",
   trip_detail_access: "QR-Code zum Reiseportal",
   status_trip_requested_description: "Vielen Dank für Ihre Anfrage",
   status_trip_requested_without_responsible_description: "Ihr Angebot wird erstellt",
   status_trip_cancelled: 'Die Buchung wurde storniert',
   status_trip_cancelled_description: 'Wir bedauern, dass Sie die Reise absagen mussten.',
   status_offer_pending_description: "Sie haben unser Angebot erhalten und können es über den Buchungslink online buchen. Beachten Sie dabei das Gültigkeitsdatum.",
   status_offer_booked_description: "Fast geschafft! Sie haben Ihr Angebot gebucht. DB prüft die Buchung und sendet Ihnen kurzfristig eine Bestätigung zu.",
   status_on_trip_description: "Die Reise hat begonnen. Wir wünschen Ihnen eine schöne Zeit.",
   status_on_trip: "Die Reise läuft",
   status_trip_requested: "Anfrage erstellt",
   status_offer_number_pending: "Mein Angebot anzeigen",
   status_awaiting_trip_advisor: "Bearbeitung offen",
   status_awaiting_offer_from_db: "in Bearbeitung",
   status_awaiting_for_your_booking: "Hier können Sie Preise einsehen und die Buchung vornehmen",
   status_offer_rejected: "Das Angebot wurde zurückgewiesen",
   status_awaiting_new_offer_from_db: "",
   status_offer_rejected_description: "Wir arbeiten an einem neuen Angebot",
   status_items_pending_approval_from_db: "Prüfung durch DB läuft",
   status_dirty: 'Wir bearbeiten Ihren Vorgang',
   status_dirty_description: 'Bei Änderungen informieren wir Sie schnellstmöglich',
   participant_summary_clarfication: 'Gruppenstruktur',
   status_items_pending_approval_from_db_description: 'Die Änderungen werden von DB geprüft. Sie erhalten in Kürze eine Rückmeldung.',
   documents_tooltip_text: 'Ausweis-Informationen erforderlich',
   documents_completed: 'Fertiggestellt',
   documents_missing: 'Fehlt',
   continue_and_reject_existing_offer: 'Gewünschte Erweiterung an DB senden',
   you_have_a_pending_offer: 'Angebot ändern',
   add_program_and_reject_pending_offer_modal_text: 'Wir freuen uns, dass Sie das bestehende Angebot erweitern möchten. Wir werden uns in Kürze bei Ihnen rückmelden. Bis dahin ist keine Buchung möglich',
   consent_title: 'consent title', // not used?
   consent_modal_text: 'Zur Darstellung der Karte wird Google Maps verwendet. Um Ihnen die Karte anzeigen zu können, ist es erforderlich, dass Sie der Nutzung von Google Maps zustimmen. Dabei können persönliche Daten wie Ihre IP Adresse an Google übermittelt werden. Weitere Informationen finden Sie unter <a href="https://www.db-gruppen.de/dataprivacy" target="_blank" class="h-link">Datenschutz</a>.<br><br>Bei Zustimmung, wird Google Maps für Sie aktiviert und Ihre Präferenz im Bezug auf Google Maps wird in einem Cookie gespeichert.',
   review_meal_plans: 'Zur Verpflegung',
   request_comments: 'Kommentar',
   status_offer_expired_description: 'Ihr Angebot ist leider verfallen',
   decline: 'decline', // not used?
   status_offer_expired: 'Angebot abgelaufen',
   request_a_new_one: 'Mitteilung an DB-Klassenfahrten und Gruppenreisen',
   required_field_clarification: 'Pflichtfelder <br>Zur Vervollständigung und Übermittlung an DB Klassenfahrten und Gruppenreisen, ist es erforderlich, die Pflichtfelder auszufüllen',
   accept: 'accept', // not used?
   offer_already_expired_modal_input_placeholder: 'Ihr Kommentar',
   send_request: 'Anfrage absenden',
   offer_already_expired_modal_description: 'Bitte teilen Sie uns hier mit, welche Anpassung wir für Sie vornehmen dürfen ( verlängerte Option, alternatives Reiseziel, alternativer Reisetermin)?',
   offer_already_expired_modal_title: 'Das Angebot ist abgelaufen',
   waiting_for_new_one: 'Warten auf eine neue Version',
   new_offer_request_sent: 'neue Angebotsanfrage gesendet',
   could_not_send_the_request: 'konnte die Anfrage nicht senden',
   awaiting_partner_confirmation_explanation: 'Vorgemerkt - Bestätigung noch offen',
   awaiting_partner_confirmation: 'Schwebend',
   warning_db_will_be_notified: 'Bitte teilen Sie uns im Kommentarfeld die gewünschten Uhrzeiten für die jeweiligen Mahlzeiten mit.',
   programs_waiting_partner_confirmation_text: 'einige Artikel warten noch auf Bestätigung',
   update_meal_plans: 'Verpflegungswunsch an DB senden',
};