<template>
   <Modal
      :width="2"
      :visible="isModalVisible"
      @closeModal="setProgramInfoModalVisibility(false)"
      class="b-program-info-modal"
   >
      <template v-slot:header> {{ title }} </template>

         <template v-if="selectedProgram" v-slot:body>
            <img class="b-program-info-modal__main-image" :src='selectedProgram.image'>
            <div v-html="selectedProgram.description"></div>
            <div v-for="extra in selectedProgram.extras" :key="extra.id">
               {{ extra.title}}
            </div>
         </template>

         <template v-slot:footer v-if="wasBooked">
            <a
               @click="setProgramInfoModalVisibility(false)"
               href="javascript:void(0);"
               class="h-cancel"
            >
               {{ $t("cancel") }}
            </a>
            <Button
               @click="onAddClick"
               :label="$t('add_to_trip')"
            />
         </template>
   </Modal>
</template>

<script>
   import { mapState, mapMutations } from 'vuex';
   import Modal from '@/components/shared/Modal.vue';

   export default {
      components: {
         Modal,
      },
      computed: {
         ...mapState({
            isModalVisible: state => state.dashboard.showProgramInfoModal,
            selectedProgram: state => state.dashboard.selectedProgram,
            wasBooked: state => state.wasBooked,
         }),
         title() {
            return this.selectedProgram ? this.selectedProgram.title : '';
         },
      },
      methods: {
         ...mapMutations([
            'setAddProgramModalVisibility',
            'setProgramInfoModalVisibility',
         ]),
         onAddClick() {
            this.setAddProgramModalVisibility(true);
            this.setProgramInfoModalVisibility(false);
         }
      },
   }
</script>

<style lang="less">
   .b-program-info-modal {
      &__main-image {
         width: 100%;
         height: 215px;
         object-fit: cover;
      }

      strong {
         .h-font-bold;
      }
   }
</style>