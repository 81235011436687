export const tripStatusName = {
   TRIP_REQUESTED: 'TRIP_DETAIL_STATUS_TRIP_REQUESTED',
   TRIP_ASSIGNED_DB_USER: 'TRIP_DETAIL_STATUS_TRIP_ASSIGNED_DB_USER',
   OFFER_AWAITING: 'TRIP_DETAIL_STATUS_OFFER_AWAITING',
   OFFER_EXPIRED: 'TRIP_DETAIL_STATUS_OFFER_EXPIRED',
   OFFER_REJECTED: 'TRIP_DETAIL_STATUS_OFFER_REJECTED',
   OFFER_BOOKED: 'TRIP_DETAIL_STATUS_OFFER_BOOKED',
   OFFER_CONFIRMED: 'TRIP_DETAIL_STATUS_OFFER_CONFIRMED',
   ITEMS_PENDING_APPROVAL: 'TRIP_DETAIL_STATUS_ITEMS_PENDING_APPROVAL',
   ITEMS_PAX_UPDATED: 'TRIP_DETAIL_STATUS_ITEMS_PAX_UPDATED',
   PROCESSING_REQUEST: 'TRIP_DETAIL_STATUS_PROCESSING_REQUEST',
   ON_TRIP: 'TRIP_DETAIL_STATUS_ON_TRIP',
   TRIP_CANCELLED: 'TRIP_DETAIL_STATUS_TRIP_CANCELLED',
   TRIP_ARCHIVED: 'TRIP_DETAIL_STATUS_TRIP_ARCHIVED',
}
