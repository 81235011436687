<template>
   <div>
      <div
         class="b-timeline-item"
         :class="{
            'is-end-point'   : locationItem.isEndingPoint,
            'is-destination' : locationItem.isDestination,
            'is-start-point' : locationItem.isStartingPoint,
         }"
      >
         <!-- line & dot -->
         <div class="b-timeline-item__left">
            <div class="b-timeline-item__line"></div>
            <div class="b-timeline-item__dot"></div>
         </div>

         <!-- content -->
         <slot></slot>
      </div>

      <TimeLineItem
         v-for="item in locationItem.items"
         :item="item"
         :key="item.title + item.date + item.type"
      >
         <transition name="bounce">
            <!-- 
               The "max-width" style was added to avoid issues with the title when using ellipsis
            -->
            <div v-if="isProgram(item)" style="max-width: calc(100% - 35px);">
               <div class="b-timeline-item__date has-no-margin is-program-date">
                  {{ item.formattedDate }}
               </div>

               <div
                  v-for="program in item.programs"
                  :key="program.title"
                  :title="`${program.date} ${program.title} `"
                  class="b-timeline-item__title-outter"
               >
                  <div class="b-timeline-item__title"> {{ program.title }} </div>

                  <div class="b-timeline-item__time" v-if="program.time">
                     {{ program.time }}
                  </div>

                  <!-- not confirmed warning -->
                  <span
                     class="h-awaiting-partner-booking"
                     v-if="program.isAwaitigPartnerConfirmation"
                     v-tooltip.top="$t('awaiting_partner_confirmation_explanation')"
                  >  
                     {{ $t('awaiting_partner_confirmation') }}
                  </span>
               </div>
            </div>
         </transition>

         <span v-if="!isProgram(item)">
            {{ item.title }}
            <span v-if="isTransportation(item)" class="b-timeline-item__date">
               {{ item.formattedDate }}
            </span>

            <template v-if="item.has_alternatives && !isReadOnlyNoSensitiveData">
               <br>
               <a
                  target="_blank"
                  :href="offers.last.link"
                  class="b-timeline-item__alternative-warning"
               >
                  {{ $t("check_offer_for_alternatives") }}
               </a>
            </template>
         </span>
      </TimeLineItem>
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import { typeName as serviceName} from '@/constants/service.js';
   import TimeLineItem from '@/components/dashboard/timeline/TimeLineItem.vue';
   import Tooltip from 'primevue/tooltip';

   export default {
      components: {
         TimeLineItem
      },
      props: {
         locationItem: {
            type: Object,
            required: true,
         },
      },
      directives: {
         'tooltip': Tooltip
      },
      computed: {
         ...mapState([
            'responsible',
            'offers'
         ]),
      },
      methods: {
         isTransportation(item) {
            return item.type == serviceName.TRANSPORTATION;
         },
         isProgram(item) {
            return item.type == serviceName.PROGRAM;
         },
      },
   };
</script>

<style>
</style>