<template>
   <div v-if="tripStatus" class="b-trip-status" :class="statusClass">
      <div
         class="b-trip-status__main-text" @click="openStatusGuideModal"
         style="cursor:pointer"
      >
         {{ mainText }}
      </div>
      <div
         v-if="secondaryText"
         style="cursor:pointer"
         @click="openStatusGuideModal"
         class="b-trip-status__secondary-text"
      >
         <div @click.stop="onSecondaryTextClick" v-html="secondaryText"></div>
      </div>
   </div>

   <StatusGuideModal
      :tripStatusClass="statusClass"
      :isModalVisible="showStatusGuideModal"
      @close="showStatusGuideModal = false"
   />

   <RequestNewOfferModal/>

</template>

<script>
   import { mapState, mapMutations } from 'vuex';
   import tripStatuses from '@/constants/tripStatuses';
   import StatusGuideModal from '@/components/shared/header/status/StatusGuideModal.vue';
   import RequestNewOfferModal from '@/components/shared/header/RequestNewOfferModal.vue';

   export default {
      components: {
         StatusGuideModal,
         RequestNewOfferModal,
      },
      data() {
         return {
            showStatusGuideModal: false,
            showRebookModal: false,
         };
      },
      computed: {
         ...mapState({
            bookOfferLink: state => state.offers.last.link,
            hasItemsPendingApproval: state => state.pendingPrograms,
            hasOfferRebookRequest: state => state.offerRebookRequested,
            hasProgramsAwaitingPartnerConfirmation: state => state.programsAwaitingPartnerConfirmation,
            hasResponsible: state => state.responsible,
            tripHasOffers: state => state.offers.length > 0,
            tripStatus: state => state.tripStatus,
         }),
         mainText() {
            return this.$t(tripStatuses[this.tripStatus].mainText);
         },
         secondaryText() {
            let secondaryText = tripStatuses[this.tripStatus].secondaryText;

            if (this.offerHasExpired) {
               return this.hasOfferRebookRequest ? this.$t('waiting_for_new_one') : `<a> ${this.$t('request_a_new_one')}</a>`;
            }

            if (this.offerIsConfirmed && this.hasProgramsAwaitingPartnerConfirmation) {
               return this.$t('programs_waiting_partner_confirmation_text');
            }

            if (this.offerHasBeenSent) {
               return `<a href="${this.bookOfferLink}" target="_blank"> ${this.$t(secondaryText)}</a>`;
            }

            if (!secondaryText) {
               return '';
            }

            return this.$t(secondaryText);
         },
         offerHasExpired() {
            return this.tripStatus == 'TRIP_DETAIL_STATUS_OFFER_EXPIRED';
         },
         offerIsConfirmed() {
            return this.tripStatus == 'TRIP_DETAIL_STATUS_OFFER_CONFIRMED';
         },
         offerHasBeenSent() {
            return this.tripStatus == 'TRIP_DETAIL_STATUS_OFFER_AWAITING';
         },
         statusClass() {

            if(!this.hasResponsible) {
               return 'is-status-trip-requested';
            }

            if(this.hasItemsPendingApproval) {
               return 'is-status-items-pending-approval';
            }

            if (!this.tripStatus) {
               return '';
            }
            return 'is-status-' + tripStatuses[this.tripStatus].prefix;
         },
      },
      methods: {
         ...mapMutations([
            'setPendingOfferWarningModalVisibility',
            'setRequestNewOfferModalVisibility',
         ]),
         onSecondaryTextClick() {
            if (this.hasOfferRebookRequest || !this.offerHasExpired) {
               return;
            }

            this.setRequestNewOfferModalVisibility(true);
         },
         openStatusGuideModal() {
            this.showStatusGuideModal = true;
         }
      },
   }
</script>

<style lang="less">
   .b-trip-status {
       --status-color: black;
       --status-background-color: black;

       --status-size: 15px;

       background-color: var(--status-background-color);
       font-size: var(--status-size);
       padding:
           calc(var(--status-size) ~"* 0.5")
           var(--status-size)
           calc(var(--status-size) ~"* 0.4")
           calc(var(--status-size) ~"* 0.75");
       line-height: 1;
       border-radius: var(--status-size);
       border: solid 2px var(--status-color);
       .h-flex-row-left-center;

       &:before { // dot
           content: '';
           width: calc(var(--status-size) ~"* 0.80");
           height: calc(var(--status-size) ~"* 0.80");
           background-color: var(--status-color);
           border-radius: 100%;
           margin-right: calc(var(--status-size) ~"* 0.7");
       }

       &__main-text {
           .h-font-bold;
       }

       &__secondary-text {
           padding-left: calc(var(--status-size) ~"* 0.35");

           a {
               color: var(--color-red);
               text-decoration: underline;
           }
       }

       // statuses
       &.is-status-trip-requested,
       &.is-status-trip-requested-db,
       &.is-status-processing-request,
       &.is-status-2, {
           --status-color: @status-color-1;
           --status-background-color: fade(@status-color-1, 10%);
       }
       &.is-status-offer-awaiting,
       &.is-status-offer-expired {
           --status-color: @status-color-3;
           --status-background-color: fade(@status-color-3, 10%);
       }
       &.is-status-offer-rejected {
           --status-color: @status-color-5;
           --status-background-color: fade(@status-color-5, 10%);
       }
       &.is-status-offer-booked {
           --status-color: @status-color-6;
           --status-background-color: fade(@status-color-6, 10%);
       }
       &.is-status-offer-confirmed,
       &.is-status-on-trip {
           --status-color: @status-color-7;
           --status-background-color: fade(@status-color-7, 10%);
       }
       &.is-status-items-pending-approval,
       &.is-status-items-pax-updated {
           --status-color: @status-color-8;
           --status-background-color: fade(@status-color-8, 10%);
       }
       &.is-status-trip-canceled {
           --status-color: @status-color-11;
           --status-background-color: fade(@status-color-11, 10%);
       }
       &.is-status-trip-archived {
           --status-color: @status-color-12;
           --status-background-color: fade(@status-color-12, 10%);
       }
   }
</style>