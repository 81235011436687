<template>
   <div class="b-header">
      <!-- DB operator badge -->
      <div class="b-header__db-operator-badge" v-if="isDb">
         DB Operator
      </div>

      <!-- logo and beta tag-->
      <div class="b-header__logo-and-beta">
         <img src="@/assets/img/db-iso.svg" class="b-header__logo" />
         <div class="b-header__beta" v-if="!isDb">Beta</div>
      </div>

      <!-- main content -->
      <div class="b-header__main-content">
         <!-- top content -->
         <div class="b-header__top-content">
            <!-- title -->
            <div class="b-header__title"> {{ headerTitle }} </div>

            <!-- trip code -->
            <div class="b-header__trip-code" v-if="accessGranted">
               <div class="b-header__trip-code-label"> {{ $t("trip_code") }} </div>
               <div class="b-header__trip-code-value"> {{ tripCode }} </div>
            </div>

            <div class="b-header__separator"></div>

            <!-- status -->
            <TripStatus />
         </div>

         <!-- bottom content -->
         <div class="b-header__bottom-content" v-if="accessGranted">
            <div class="b-header__bottom-left">
               {{ $t('process') }}: #{{ checkoutNumber }}
               <OfferInfo />
            </div>

            <div class="b-header__bottom-right">
               <HeaderActions />
            </div>
         </div>
      </div>

      <SaveBar />
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import navigation from '@/mixins/navigation.js';
   import SaveBar from '@/components/participant_list/SaveBar.vue';
   import OfferInfo from '@/components/shared/header/OfferInfo.vue';
   import TripStatus from '@/components/shared/header/status/TripStatus.vue';
   import HeaderActions from '@/components/shared/header/actions/HeaderActions.vue';

   export default {
      components: {
         SaveBar,
         OfferInfo,
         TripStatus,
         HeaderActions,
      },
      mixins: [ navigation ],
      computed: {
         ...mapState({
            tripName: state => state.tripName,
            tripCode: state => state.tripCode,
            isLoading: state => state.loading.full,
            accessGranted: state => state.accessGranted,
            checkoutNumber: state => state.checkoutNumber,
            isDb: state => state.isDb,
         }),
         headerTitle() {
            if (this.isLoading) {
               return this.$t('loading') + '...';
            }

            return this.accessGranted ? this.tripName : this.$t('unauthorized_access');
         },
      },
   }
</script>

<style lang="less">
   .b-header { 
       background-color: white;
       padding: 15px var(--page-padding);
       position: sticky;
       top: 0;
       //z-index -> managed in helpers.less (z-index section)
       width: 100%;
       box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
       min-height: 92px;
       .flex-shrink(0);
       .h-flex-row-left-top;

       &__db-operator-badge {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #22b473;
          color: white;
          padding: 4px 15px;
          border-radius: 0 5px 0 0;
          font-size: calc((12 / var(--base-value)) * 1rem);
          text-transform: uppercase;
          .h-font-bold;
       }

       &__logo-and-beta {
           margin-right: 20px;
           .flex-shrink(0);
           .h-flex-col-center;
       }

       &__beta {
           background-color: var(--color-gray-4);
           padding: 4px 15px;
           line-height: 1;
           text-align: center;
           display: inline-block;
           margin-top: 8px;
           border-radius: 10px;
           font-size: calc((12 / var(--base-value)) * 1rem);
           .h-font-regular;
       }

       &__logo {
           width: 65px;
       }

       &__main-content {
           .flex-grow(1);
       }

       &__title {
           line-height: 1;
           margin-right: 10px;
           .h1-style;
           font-size: calc((28 / var(--base-value)) * 1rem);
           .flex-shrink(0);
       }

       &__top-content {
           .h-flex-row-left-center;
           .flex-wrap(wrap);
       }

       &__trip-code {
           line-height: 1;
           margin-right: 20px;
           position: relative;
           .align-self(flex-end);
       }

       &__trip-code-label {
           font-size: calc((13 / var(--base-value)) * 1rem);
           position: absolute;
           left: 0;
           right: 0;
           top: -15px;
           color: var(--color-gray-2);
           text-align: center;
       }

       &__trip-code-value {
           font-size: calc((20 / var(--base-value)) * 1rem);
           text-transform: uppercase;
       }

       &__bottom-content {
         color: var(--color-gray-2);
         .h-flex-row-left-top;
       }

       &__bottom-left {
          .flex-grow(1);
       }

       &__bottom-right {
          .flex-shrink(0);
          .align-self(flex-end);
       }

       @media only screen and (max-width : @breakpoint-md) {
           padding-left: 15px;

           &__logo-and-beta {
               margin-right: 10px;
           }

           &__logo {
               width: 35px;
           }

           &__beta {
               padding: 5px;
           }

           &__title {
               .flex-wrap(wrap);
           }

           &__separator {
               .flex-grow(1);
           }

           &__bottom-right {
               .h-flex-col;
               .align-items(flex-end);
           }
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           &__db-operator-badge {
              font-size: calc((10 / var(--base-value)) * 1rem);
           }

           &__trip-code-label {
               top: -12px;
           }

           &__title {
              font-size: calc((23 / var(--base-value)) * 1rem);
           }

           &__trip-code-value {
              font-size: calc((20 / var(--base-value)) * 1rem);
           }

           &__separator {
              width: 100%;
           }

           .b-trip-status {
               --status-size: 12.5px;

               margin-top: 6px;
               margin-bottom: 6px;
           }
       }
   }
</style>