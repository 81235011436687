import { createStore } from 'vuex';

// modules
import dashboard from './modules/dashboard.js';
import tripDetails from './modules/tripDetails.js';
import communication from './modules/communication.js';
import participantList from './modules/participantList.js';

// api services
import mealService from '../services/meal.js';
import dashboardService from '../services/dashboard.js';

let storeObject = {

   state: {
      accessToken: null,
      accessGranted: false,
      canEditParticipantList: false,
      consent: null,
      isPassportRequired: false,
      canRequestPrograms: false,
      checkoutNumber: null,
      countries: [],
      customerAccount: null,
      customerStatus: null,
      departureDate: null,
      returnDate: null,
      destinations: [],
      groupStructure: null,
      hasFligthTransportation: false,

      loading: {
         full: false,
         page: false,
         requestNewOfferModal: false,
         component: false,
      },

      isDb: false,
      isPartner: false,
      isClassTrip: false,
      isGroupTrip: false,
      offerRebookRequested: false,
      offers: [],
      origin: null,
      openMealPlans: false,
      participantListIsLocked: false,
      pendingPrograms: false,
      programsAwaitingPartnerConfirmation: false,
      printViewLink: 'javascript:void(0)',
      rdsTimeStamp: '',
      readOnlyNoSensitiveDataMode: false,
      responsible: null,
      showHelpModal: false,
      isMobile:false,
      toastService: null,
      totalPax: null,
      tripDateRange: [],
      tripCode: null,
      tripName: '',
      tripStatus: null,
      tripType: '',
      unauthorizedAccess: false,
      warnings: null,
      writeAccessMode: false,
      mealPlanData: null,
      wasBooked: false,
   },

   getters: {
      totalPax: state => state.totalPax,
      accessToken: state => state.accessToken,
      dataHasBeenSet: state => state.accessGranted,
      hasConsented: state => state.consent == true,
      showCommunicationSection: state => state.accessToken == '64DC8lCdzthmlvhm' || state.accessToken == 'bZ9J8LWGmrS8tx4o',
   },

   actions: {

      displayError({ state }, { summary, detail }) {
         state.toastService.add({ severity:'error', summary, detail, group: 'br', life: 4000 });
      },

      displayMessage({ state }, { summary, detail }) {
         state.toastService.add({ severity:'success', summary, detail, group: 'br', life: 4000 });
      },
      refreshMealPlanData({commit}) {
         return  mealService.fetchMealPlanData().then(data => commit('setMealPlanData', data));
      },

      async fetchData({ state, commit, dispatch, getters }) {
         try {
            commit('toggleFullLoading');

            dispatch('fetchTripDetailsData');

            if (getters.showCommunicationSection) {
               dispatch('fetchCommunicationData').then(() => dispatch('initMessagePolling'));
            }

            let data = await dashboardService.fetchData();

            commit('setTripData', data.trip);

            if (state.isPartner) {
               document.title = `Teilnahmeliste ${state.checkoutNumber}`;
            }
            
            commit('setMealPlanData', data.mealPlanData);
            commit('setDashboardData', data);
            commit('setParticipantListData', data.trip);
            commit('setAccessGranted');

            commit('toggleFullLoading');
         } catch (error) {
            console.log(error);
            commit('toggleFullLoading');
         }
      },

      async fetchParticipantPrintData({ commit }) {
         try {
            commit('toggleFullLoading');
            let data = await dashboardService.fetchData();

            commit('setTripData', data.trip);
            commit('setParticipantListData', data.trip);

            commit('toggleFullLoading');
         } catch (error) {
            console.log(error);
            commit('toggleFullLoading');
         }
      }
   },

   mutations: {
      setAccessToken(state, token) {
         state.accessToken = token;
      },
      setConsent(state, consent) {
         document.cookie = `consent=${consent}`;
         state.consent = consent;
      },
      setFullLoading(state, loading) {
         state.loading.full = loading;
      },

      setOfferRebookRequested(state, value) {
         state.offerRebookRequested = value;
      },

      setPageLoading(state, loading) {
         state.loading.page = loading;
      },

      setRequestNewOfferLoading(state, loading) {
         state.loading.requestNewOfferModal = loading;
      },

      setTripStatus(state, status) {
         state.tripStatus = status;
      },

      setUnauthorizedAccess(state) {
         state.unauthorizedAccess = true;
      },
      setAccessGranted(state) {
         state.accessGranted = true;
      },
      togglePageLoading(state) {
         state.loading.page = !state.loading.page;
      },
      setMealPlanData(state,mealPlanData){
         state.mealPlanData = mealPlanData;
      },
      setIsMobile(state, isMobile) {
         state.isMobile = isMobile;
      },
      toggleFullLoading(state) {
         state.loading.full = !state.loading.full;
      },
      setToast(state, toastService) {
         state.toastService = toastService;
      },
      setMealPlansCollapse(state) {
         state.openMealPlans = true;
      },
      setTripData(state, data) {

         state.canEditParticipantList  = data.canEditParticipantList;
         state.canRequestPrograms      = data.canRequestPrograms;
         state.checkoutNumber          = data.checkoutNumber;
         state.countries               = data.countries;
         state.customerAccount         = data.customerAccount;
         state.customerStatus          = data.customerStatus;
         state.departureDate           = data.departureDate;
         state.isPassportRequired      = data.isPassportRequired;
         state.returnDate              = data.returnDate;
         state.destinations            = data.destinations;
         state.groupStructure          = data.groupStructure;
         state.hasFligthTransportation = data.hasFligthTransportation;
         state.isDb                    = data.isDb;
         state.isPartner               = data.isPartner;
         state.isClassTrip             = data.isClassTrip;
         state.isGroupTrip             = data.isGroupTrip;
         state.offerRebookRequested    = data.offerRebookRequested;
         state.offers                  = data.offers;
         state.origin                  = data.origin;
         state.openMealPlans           = data.openMealPlans;
         state.participantListIsLocked = data.participantListIsLocked;
         state.pendingPrograms         = data.pendingPrograms;
         state.programsAwaitingPartnerConfirmation  = data.programsAwaitingPartnerConfirmation,
         state.printViewLink           = data.printViewLink;
         state.rdsTimeStamp            = data.rdsTimeStamp;
         state.readOnlyNoSensitiveDataMode = data.readOnlyNoSensitiveDataMode;
         state.responsible             = data.responsible;
         state.showHelpModal           = data.showHelpModal;
         state.totalPax                = data.totalPax;
         state.tripDateRange           = data.tripDateRange;
         state.tripCode                = data.tripCode;
         state.tripName                = data.tripName;
         state.tripStatus              = data.tripStatus;
         state.tripType                = data.tripType;
         state.warnings                = data.warnings;
         state.writeAccessMode         = data.writeAccessMode;
         state.wasBooked              = data.wasBooked;
         state.isMobile                = false; // TODO @dev: make this value dynamic
      },

      resetState(state) {
         state.accessToken             = null;
         state.accessGranted           = false;
         state.canEditParticipantList  = false;
         state.consent                 = null;
         state.isPassportRequired      = false;
         state.canRequestPrograms      = false;
         state.checkoutNumber          = null;
         state.countries               = [];
         state.customerAccount         = null;
         state.customerStatus          = null;
         state.departureDate           = null;
         state.returnDate              = null;
         state.destinations            = [];
         state.groupStructure          = null;
         state.hasFligthTransportation = false;
         state.loading.full            = false;
         state.loading.page            = false;
         state.loading.component       = false;
         state.isDb                    = false;
         state.isClassTrip             = false;
         state.isGroupTrip             = false;
         state.isPartner               = false;
         state.offerRebookRequested    = false;
         state.offers                  = [];
         state.origin                  = null;
         state.openMealPlans           = false;
         state.participantListIsLocked = false;
         state.pendingPrograms         = false;
         state.programsAwaitingPartnerConfirmation  = false;
         state.printViewLink           = 'javascript:void(0)';
         state.rdsTimeStamp            = '';
         state.readOnlyNoSensitiveDataMode = false;
         state.responsible             = null;
         state.showHelpModal           = false;
         state.totalPax                = null;
         state.tripCode                = null;
         state.tripDateRange           = [];
         state.tripName                = '';
         state.tripStatus              = null;
         state.tripType                = '';
         state.unauthorizedAccess      = false;
         state.warnings                = null;
         state.writeAccessMode         = false;
         state.mealPlanData            = null;
         state.wasBooked              = false;
         state.isMobile                = false;
      }
   },

   modules: {
      dashboard,
      tripDetails,
      communication,
      participantList,
   }
};

export default createStore(storeObject);