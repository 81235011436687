<template>
   <Modal
   :width="2"
      :visible="isModalVisible"
      class="b-add-program-modal"
      @closeModal="setAddProgramModalVisibility(false)"
   >
      <template v-slot:header> {{ $t('add_program') }} </template>

      <template v-slot:body>

         <div class="h-input-label">
            {{ $t('add') }}
            <strong class="h-font-bold"> {{ title }} </strong>
            {{ $t('for') }}:
         </div>

         <!-- school participants detail -->
         <div class="b-add-program-modal__pax" v-if="!isGroupTrip">
            <InputNumber
               :min="0"
               showButtons
               mode="decimal"
               :allowEmpty="false"
               id="minmax-buttons"
               v-model="studentPaxForProgram"
               :max="groupStructure.kidsTotal"
            />
            {{ $t('students') }}
         </div>

         <div class="b-add-program-modal__pax h-margin-top-15" v-if="!isGroupTrip">
            <InputNumber
               :min="0"
               showButtons
               mode="decimal"
               :allowEmpty="false"
               v-model="teacherPaxForProgram"
               :max="groupStructure.adultsTotal"
            />
            {{ $t('teachers') }}
         </div>

         <!-- group participants detail -->
         <div class="b-add-program-modal__pax" v-if="isGroupTrip">
            <InputNumber
               :min="0"
               showButtons
               mode="decimal"
               :allowEmpty="false"
               :max="groupStructure.total"
               v-model="travellersPaxForProgram"
            />
            {{ $t('travellor') }}
         </div>

         <!-- price change warning  -->
         <transition name="bounce">
            <div v-if="programPaxDiffersFromTotalPax" class="h-color-not-ok h-margin-top-10">
               {{ $t('rds_warning_pax_change_might_affect_price') }}
            </div>
         </transition>

         <!-- date -->
         <div class="h-margin-form-item">
            <div class="h-input-label"> {{ $t('on_the_day') }} </div>

            <Dropdown
               v-model="serviceDate"
               optionLabel="label"
               optionValue="value"
               :options="serviceDateOptions"
            />
         </div>

         <!-- time -->
         <div class="h-margin-form-item">
            <div class="h-input-label"> {{ $t('time') }} </div>

            <Calendar 
               :timeOnly="true" 
               :showIcon="true" 
               icon="pi pi-clock"
               dateFormat="HH:mm"
               :manualInput="false"
               :stepMinute="15"
               v-model="serviceTime" 
            />
         </div>

         <!-- comments -->
         <div class="h-margin-form-item">
            <Textarea v-model="programComment" :placeholder="$t('want_to_enter_comment')" :autoResize="true"/>
         </div>

         <!-- extras -->
         <div class="b-add-program-modal__extras-title" v-if="hasExtras"> {{ $t("available_extras") }} </div>

         <template v-if="selectedProgram">
            <ProgramExtraItem v-for="extra in selectedProgram.extras" :key="extra.id" :item="extra"/>
         </template>

         <div class="b-add-program-modal__bottom-text"> {{ $t('this_will_also_send_request') }} </div>

         <VueElementLoading :active="loading" spinner="bar-fade-scale"/>
      </template>

      <template v-slot:footer>
         <Button @click="onAddClick" :label="$t('add_to_trip')" />
      </template>
   </Modal>
</template>

<script>
   import Textarea from 'primevue/textarea';
   import Dropdown from 'primevue/dropdown';
   import Calendar from 'primevue/calendar';
   import dateManager from '@/helpers/date.js';
   import { formats } from '@/constants/date.js';
   import InputNumber from 'primevue/inputnumber';
   import Modal from '@/components/shared/Modal.vue';
   import VueElementLoading from 'vue-element-loading';
   import ProgramExtraItem from '@/components/dashboard/suggested_programs/ProgramExtraItem.vue';
   import { mapState, mapMutations, mapActions } from 'vuex';


   export default {
      components: {
         Modal,
         Textarea,
         Dropdown,
         Calendar,
         InputNumber,
         ProgramExtraItem,
         VueElementLoading,
      },
      data() {
         return {
            loading: false,
            programComment: '',
            serviceDate: null,
            serviceTime: '09:00',
            studentPaxForProgram: 0,
            teacherPaxForProgram: 0,
            travellersPaxForProgram: 0,
         };
      },
      computed: {
         ...mapState({
            isGroupTrip: state => state.isGroupTrip,
            isModalVisible: state => state.dashboard.showAddProgramModal,
            groupStructure: state => state.groupStructure,
            selectedProgram: state => state.dashboard.selectedProgram,
            selectedExtras: state => state.dashboard.selectedExtras,
            tripDates: state => state.tripDateRange,
            participants: state => state.participantList.participants
         }),
         participantsProcessId() {
            if(!this.participants[0]) {
               return;
            }
            return this.participants[0]['process_id'];
         },
         selectedExtrasId() {
            return this.selectedExtras.map(extra => extra.id);
         },
         hasExtras() {
            if (!this.selectedProgram) {
               return false;
            }

            return !!this.selectedProgram.extras.length;
         },
         paxForProgram() {
            if (!this.isGroupTrip) {
               return parseInt(this.studentPaxForProgram) + parseInt(this.teacherPaxForProgram);
            }

            return parseInt(this.travellersPaxForProgram);
         },
         programPaxDiffersFromTotalPax() {
            return this.paxForProgram != this.groupStructure.total;
         },
         serviceDateOptions() {
            return this.tripDates.map(aDate => {
               let formatedDate = dateManager.format(aDate,
                  formats.DATABASE,
                  formats.DISPLAY_WITH_WEEKDAY
               );

               return { label: formatedDate, value: aDate };
            });
         },
         title() {
            if (!this.selectedProgram) {
               return this.$t('no_info_available');
            }

            return this.selectedProgram.title;
         },
      },
      methods: {
         ...mapMutations([
            'setAddProgramModalVisibility',
         ]),
         ...mapActions([
            'addProgramToTrip',
            'fetchTripDetailsData'
         ]),
         formatServiceTime() {

            if (typeof this.serviceTime == 'string') {
               return this.serviceTime;
            } 
            var minutes = this.serviceTime.getMinutes().toString();
            var hours = this.serviceTime.getHours().toString();

            if(minutes.length == 1) {
              minutes = '0' + minutes;
            }
            if(hours.length == 1) {
              hours = '0' + hours;
            }
            return  hours + ':' + minutes; 
         },
         onAddClick() {
            if (this.loading) {
               return;
            }

            this.loading = true;

            //TODO: change case of the expected payload
            let payload = {
               extras: this.selectedExtrasId,
               service_date: this.serviceDate,
               program_id: this.selectedProgram.id,
               time_departure_start: this.formatServiceTime(),
               pax: this.paxForProgram,
               paxKids: this.studentPaxForProgram,
               paxAdults: this.teacherPaxForProgram,
               process_id:this. participantsProcessId,
               comment: this.programComment
            };

            this.addProgramToTrip(payload).finally(() => {
               this.loading = false;
               this.fetchTripDetailsData();
            });

         }
      },
      created() {
         this.travellersPaxForProgram = this.groupStructure.total;
         this.studentPaxForProgram = this.groupStructure.kidsTotal;
         this.teacherPaxForProgram = this.groupStructure.adultsTotal;
      },
      mounted() {
         this.serviceDate = this.serviceDateOptions[0].value;
         let date = new Date();
         date.setHours(9,0,0);
         this.serviceTime = date;
      }
   }
</script>

<style lang="less">
   .b-add-program-modal {
      &__pax {
         margin-top: 5px;
         .h-flex-row-left-center;

         .p-inputnumber {
            margin-right: 10px;
            width: 100px;

            .p-button {
               background-color: var(--color-gray-3);

               &:enabled:hover {
                  background-color: var(--color-gray);
               }
            }
         }
      }

      &__extras-title {
         text-transform: uppercase;
         margin-top: var(--form-item-margin);
      }

      &__bottom-text {
          margin-top: 20px;
          border-top: dashed 1px var(--color-gray-3);
          padding-top: 20px;
          text-align: right;
          .h-font-bold;
      }

      .p-calendar-timeonly {
         width: 100px;
      }

      .p-dropdown {
         width: 220px !important;
      }
   }
</style>