<template>
   <Modal
      :width="2"
      :visible="isVisible"
      class=""
      @closeModal="setCookiesDetailModalVisibility(false)"
   >
      <template v-slot:header> 
         {{ $t('Google maps') }}
      </template>

      <template v-slot:body>
         <span v-html="$t('consent_modal_text')"></span>
      </template>

      <template v-slot:footer>
         <a @click="setCookiesDetailModalVisibility(false)" class="h-button">
            {{ $t('close') }}
         </a>
      </template>
   </Modal>
</template>

<script>
   import { mapState, mapMutations } from 'vuex';
   import Modal from '@/components/shared/Modal.vue';

   export default {
      components: {
         Modal,
      },
      computed: {
         ...mapState({
            isVisible: state => state.dashboard.showCookiesDetailsModal,
         }),
      },
      methods: {
         ...mapMutations([
            'setCookiesDetailModalVisibility',
         ]),
      },
   }
</script>
