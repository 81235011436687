import feedService from '../../services/feed.js';
import { MESSAGE_POLLING_FREQUENCY } from '../../constants/communication.js';

export default {

   state: () => ({
      chatMessages: [],
      feedEntries: [],
   }),

   getters: {
      unreadMessagesCount: (state, getters) => getters.unreadMessages.length,
      unreadMessages: state => state.chatMessages
         .filter(message => !message.readByCustomer && !message.isFromCustomer),
   },

   actions: {

      clearUnreadMessages({ commit }, messages) {
         return feedService.clearUnreadMessages({ messages })
            .then((response) => commit('setMessages', response));
      },

      fetchChatMessages({ commit }) {
         return feedService.fetchChatMessages()
            .then((response) => commit('setMessages', response))
            .catch(error => console.log(error));
      },

      fetchCommunicationData({ dispatch }) {
         dispatch('fetchTripHistory');
         return dispatch('fetchChatMessages');
      },

      fetchTripHistory({ commit }) {
         return feedService.fetchTripHistory()
            .then(response => commit('setFeedEntries', response))
            .catch(error => console.log(error));
      },

      initMessagePolling({ dispatch }) {
         setInterval(() => dispatch('fetchChatMessages'), MESSAGE_POLLING_FREQUENCY);
      },

      sendChatMessage({ commit }, message) {
         return feedService.sendChatMessage({ message })
            .then(response => commit('pushMessage', response));
      },
   },

   mutations: {
      setCommunicationData(state, data) {
         state.feedEntries  = [...data.feedEntries];
         state.chatMessages = [...data.chatMessages];
      },

      setFeedEntries(state, feedEntries) {
         state.feedEntries  = [...feedEntries];
      },

      setMessages(state, messages) {
         state.chatMessages = [ ...messages];
      },

      pushMessage(state, newMessage) {
         state.chatMessages.push(newMessage);
      },
   },

}