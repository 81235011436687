<template>
   <div v-if="!readOnlyNoSensitiveDataMode" class="b-top-texts">
      <div>
         {{ $t("account") }}:

         <span class="b-top-texts__main-text"> {{ customerAccountName }} </span>

         <br>
         {{ $t("trip_type") }}:

         <span class="b-top-texts__main-text"> {{ tripType }} </span>
      </div>

      <div >
         {{ $t("db_contact_name") }}:
         <span class="b-top-texts__main-text"> {{ responsibleName }} </span>

         <br>
         <template v-if="responsible && responsible.phone">
            {{ $t("db_contact_phone") }}:

            <span class="b-top-texts__main-text"> {{ responsible.phone }} </span>
         </template>
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      computed: {
         ...mapState([
            'tripType',
            'responsible',
            'customerAccount',
            'readOnlyNoSensitiveDataMode',
         ]),
         customerAccountName() {
            if (!this.customerAccount) {
               return '';
            }
            return this.customerAccount.name;
         },
         responsibleName() {
            if (!this.responsible) {
               return this.$t("db_user_not_assigned_yet");
            }
            return this.responsible.name;
         },
      },
   }
</script>

<style lang="less">
   .b-top-texts {
      color: var(--color-gray-2);
      line-height: 1.4;
      margin-top: 10px;
      .h-flex-row-space-between;
      .align-items(flex-start);

      &__main-text {
         color: var(--color-gray);
         font-size: calc((16 / var(--base-value)) * 1rem);
         .h-font-regular;
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         .h-flex-col;
         .align-items(flex-start);
      }
   }
</style>