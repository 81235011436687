import { mapMutations } from 'vuex';

export default {
   methods: {
      ...mapMutations([
         'setPageLoading',
      ]),
   },
   mounted() {
      this.setPageLoading(false);
   }
}