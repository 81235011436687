<template>
   <div
      class="b-trip-page-header__offer-wrap"
      v-if="hasOffer"
   >
      <div class="b-trip-page-header__confirmed-offer" >
         {{ offers.last.status.name }}

         <a 
            :href="offers.last.link"
            class="b-trip-page-header__confirmed-offer-number"
            target="_blank"
         >
               #{{ offers.last.name }}
         </a>
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      components: {
      
      },
      props: {

      },
      data() {
         return {

         };
      },
      computed: {
         ...mapState([
            'offers'
         ]),
         hasOffer() {
            return this.offers && this.offers.length > 0;
         },
      },
      methods: {

      },
      created() {

      }
   }
</script>

<style>
</style>