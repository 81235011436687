import api from  './api.js';


function fetchTripHistory() {
   return api.get('rds/history');
}

function fetchChatMessages() {
   return api.get('rds/messages');
}

function sendChatMessage(message) {
   return api.post('rds/messages', message);
}

function clearUnreadMessages(messages) {
   return api.post('rds/messages/clear', messages);
}

export default {
   fetchTripHistory,
   fetchChatMessages,
   sendChatMessage,
   clearUnreadMessages,
}