export const formats = {
   TIME: 'HH:mm',
   TIME_WITH_SECONDS: 'hh:mm:ss',
   DATABASE: 'YYYY-MM-DD',
   DISPLAY : 'DD.MM.YYYY',
   DISPLAY_SHORT : 'DD.MM',
   SIXT: 'YYYY-MM-DDTHH:mm:ss',
   DISPLAY_WITH_WEEKDAY : 'ddd DD, MMMM YYYY',
   DISPLAY_CHECK: 'ddd DD.MM.YYYY',
};

export const weekdays = {
   MONDAY   : 1,
   TUESDAY  : 2,
   WEDNESDAY: 3,
   THURSDAY : 4,
   FRIDAY   : 5,
   SATURDAY : 6,
   SUNDAY   : 0,
};

export const weekdaysArray = [
   'sunday',
   'monday',
   'tuesday',
   'wednesday',
   'thusday',
   'friday',
   'saturday',
];