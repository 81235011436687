<template>
   <div v-if="onParticipantListPage" class="b-notification-bar" :class="{ 'is-save-shown' : unSavedChanges }">
      <div class="b-notification-bar__text">
         {{ $t("want_to_save_changes") }}
      </div>

      <div class="b-notification-bar__buttons-wrap">
         <Button @click="onSaveClick" :label="$t('save')" />
      </div>
   </div>
</template>

<script>
   import { mapState, mapMutations } from 'vuex';
   import navigation from '@/mixins/navigation.js';

   export default {
      mixins: [ navigation ],
      computed:{
         ...mapState({
            unSavedChanges: state => state.participantList.unSavedChanges
         }),
      },
      methods:{
         ...mapMutations([
            'setUnsavedChanges',
            'setSubmitParticipantsFlag',
         ]),
         onSaveClick() {
            this.setSubmitParticipantsFlag(true);
            this.hideDialog();
         },
         hideDialog() {
            this.setUnsavedChanges(false);
         },
      },
   };
</script>

<style lang="less">
   .b-notification-bar {
      position: absolute;
      height: 70px;
      padding: 20px var(--page-padding);
      width: calc(~"100% - var(--side-menu-width)");
      bottom: 0;
      left: var(--side-menu-width);
      background-color: hsl(var(--color-orange-hs) 90%);
      box-shadow: 0 5px 7px 0px rgba(0,0,0,0.5);
      border-top: solid 3px  var(--color-red);
      opacity: 0;
      .transform(translate(-150%, 100%));
      .h-flex-row-left-center;
      .transition;

      &__text {
         .flex-grow(1);
      }

      &__buttons-wrap {
         padding-left: 15px;
         .h-flex-row-left-center;
      }

      &.is-save-shown,
      &.is-export-or-print-shown {
         opacity: 1;
         .transform(translate(0%, 100%));
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         width: 100%;
         left: 0;
      }
   }
</style>