export default {
    TRIP_DETAIL_STATUS_TRIP_REQUESTED: {
        mainText: 'status_trip_requested',
        secondaryText: 'status_awaiting_trip_advisor',
        description: 'status_trip_requested_description',
        prefix: 'trip-requested',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_TRIP_ASSIGNED_DB_USER: {
        mainText: 'status_trip_requested',
        secondaryText: 'status_awaiting_offer_from_db',
        description: 'status_trip_requested_without_responsible_description',
        prefix: 'trip-requested-db',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_OFFER_AWAITING: {
        mainText: 'status_offer_number_pending',
        secondaryText: 'status_awaiting_for_your_booking',
        description: 'status_offer_pending_description',
        prefix: 'offer-awaiting',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_OFFER_EXPIRED: {
        mainText: 'status_offer_expired',
        secondaryText: '',
        description: 'status_offer_expired_description',
        prefix: 'offer-expired',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_OFFER_REJECTED: {
        mainText: 'status_offer_rejected',
        secondaryText: 'status_awaiting_new_offer_from_db',
        description: 'status_offer_rejected_description',
        prefix: 'offer-rejected',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_OFFER_BOOKED: {
        mainText: 'status_offer_booked',
        secondaryText: 'status_awaiting_confirmation_from_db',
        description: 'status_offer_booked_description',
        prefix: 'offer-booked',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_OFFER_CONFIRMED: {
        mainText: 'status_offer_confirmed',
        secondaryText: '',
        description: 'status_offer_confirmed_description',
        prefix: 'offer-confirmed',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_ITEMS_PENDING_APPROVAL: {
        mainText: 'status_items_pending_approval_from_db',
        secondaryText: '',
        description: 'status_items_pending_approval_from_db_description',
        prefix: 'items-pending-approval',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_ITEMS_PAX_UPDATED: {
        mainText: 'status_pax_updated_pending_approval_from_db',
        secondaryText: '',
        description: 'status_pax_updated_pending_approval_from_db_description',
        prefix: 'items-pax-updated',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_PROCESSING_REQUEST: {
        mainText: 'status_dirty',
        secondaryText: '',
        description: 'status_dirty_description',
        prefix: 'processing-request',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_ON_TRIP: {
        mainText: 'status_on_trip',
        secondaryText: '',
        description: 'status_on_trip_description',
        prefix: 'on-trip',
        inGuideStatusList: true
    },
    TRIP_DETAIL_STATUS_TRIP_CANCELLED: {
        mainText: 'status_trip_cancelled',
        secondaryText: '',
        description: 'status_trip_cancelled_description',
        prefix: 'trip-canceled',
        inGuideStatusList: false
    },
    TRIP_DETAIL_STATUS_TRIP_ARCHIVED: {
        mainText: 'status_trip_archived',
        secondaryText: '',
        description: 'status_trip_archived_description',
        prefix: 'trip-archived',
        inGuideStatusList: false
    },
};
