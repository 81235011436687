<template>
   <div class="b-summary-item">
      <span class="b-summary-item__icon" :class="`icon__${icon}`"></span>

      <slot>
      </slot>
   </div>
</template>

<script>
   export default {
      props: {
         icon: {
            type: String,
         }
      },
      computed: {

      },
   }
</script>

<style lang="less">
   .b-summary-item {
      line-height: 1.4;
      .h-flex-row-left-center;

      &__icon {
         font-size: calc((25 / var(--base-value)) * 1rem);
         margin-right: 10px;
         color: var(--color-gray-3);
         display: block;
      }

      &__main-text {
         font-size: calc((20 / var(--base-value)) * 1rem);
         color: var(--color-gray-1);
        .h-font-regular;
      }

      &__light-text {
         font-size: var(--base);
         color: var(--color-gray-2);
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         &__main-text {
            font-size: calc((16 / var(--base-value)) * 1rem);
         }
      }
   }
</style>