export default {
   account: "Account",
   accept_cookies_explaination: "Accept the cookies to be able to use Google maps",
   accept_the_use_of_google_maps: 'Allow cookies and show map',
   loading: "loading",
   close: "Close",
   unauthorized_access: "unauthorized access",
   trip_code: "trip code",
   print_trip_overview: "print trip overview",
   excel_export_tooltip: "export participant list",
   footer_data_privacy: "data privacy",
   participants: "Participants",
   adults: "adults",
   kids: "kids",
   teachers: "Teachers",
   students: "Students",
   db_user_not_assigned_yet: "no responsible assigned yet",
   trip_type: "Trip type",
   db_contact_name: "DB contact",
   male_short: "m",
   male: "Male",
   female_short: "w",
   female: "Female",
   db_contact_phone: "DB phone contact",
   ok: "ok",
   status_guide: "Status Guide",
   process: "process",
   status_trip_archived: "Trip Archived",
   db_partner_hotline: "+49 (0)721-9385386",
   go_to_trip_details: "go to trip details",
   dashboard: "Dashboard",
   participants_list: "Participant list",
   details: "Details",
   communication: "Comunication",
   participant_list_blocked_by_db: "The list of participants is locked by DB",
   important: "Wichtig",
   data_deletion_schedule: "The deletion of the data takes place after six months.",
   aditional_information: "Additional information",
   class_participant_list_top_message: "In order to change the number of participants, it is necessary to send us an email to {email}.",
   group_participant_list_top_message: "In order to change the number of participants, it is necessary to send us an email to {email}.",
   not_in_list: "not in the list",
   bus_drivers: "Bus driver",
   participant_list: "Participant list",
   travellers: "Travellers",
   missing: "missing",
   too_many_students_stored: "too many students",
   too_many_teachers_stored: "too many teachers",
   too_many_travellers_stored: "too many travellers",
   teachers_completed: "teachers completed",
   participants_completed: "participants completed",
   travellers_completed: "travellers completed",
   first_name: "First name",
   last_name: "Last name",
   gender: "Gender",
   birth: "Birthdate",
   participant_nationality: "Nationality",
   nationality: "Nationalität",
   select: "select",
   select_date_format: "dd.mm.yyyy",
   document_type: "Dokumentenart",
   document_number: "documment number",
   document_country: "document country",
   document_expiration: "document expiration",
   issued_on: "issued on",
   has_allergies: "allergies",
   allergies: "allergies",
   comments: "comments",
   comments_tooltip_text: "comments_tooltip_text",
   has_comments: "has comments",
   uncollapse_health: "uncollapse health",
   show_health_fields: "show health fields",
   show_comments_fields: "comments",
   participant_comments_field: "participant comments",
   participant_documents_title: "participant documents",
   other: "other",
   passport: "passport",
   gender_other: "other",
   show_document_fields: "document fields",
   personnel_pass: "ID card",
   clear: "clear",
   issuing_country: "issuing country",
   trip_detail: "Vorgangsdetails",
   check_offer_for_alternatives: "check offer for alternatives",
   persons_in: "persons in",
   check_details_in_online_catalog: "check details in online catalog",
   show_full: "show more",
   show_less: "show less",
   for_more_info_please_check: "for more info please check:",
   meal_plans: "Meal plans",
   meal_plan_not_clickable_text: "Hungry? You can add the meals to your home page.",
   meal_plan_not_clickable_link: "Please click here.",
   pending_approval: "pending approval",
   departure_time: "Departure time",
   arrival_time: "Arrival time",
   want_to_save_changes: "Want to save your changes?",
   cancel: "cancel",
   save: "save",
   pending: "pending",
   send: "senden",
   trip_history: "trip history",
   trip_messages: "messages",
   trip_communication: "communication",
   db_trip_responsible: "DB operator",
   awaiting_trip_advisor: "awaiting trip advisor",
   enter_your_message: "enter your message",
   no_value: "no value",
   more_details: "more Details",
   customer: "Customer",
   chat_you: "you",
   want_more_for_your_trip: "Want more for your trip",
   suggestions: "Trip suggestions",
   no_results_found_for: "no results found for {keyword}",
   show_x_more: "show {keyword} more",
   available_extras: "available Extras",
   price_upon_request: "price upon request",
   from_price: "from",
   add: "add",
   search_for_programs_placeholder: "search for activities",
   add_program: "Add program",
   travellor: "travellor",
   rds_warning_pax_change_might_affect_price: "pax change might affect price",
   on_the_day: "on the day",
   time: "time",
   want_to_enter_comment: "want to enter comment",
   this_will_also_send_request: "a request to DB will be sent!",
   add_to_trip: "add to trip",
   for: "for",
   price_per_person_per_day: "Price per Person/Day",
   also_includes: "included",
   see_more_details: 'See more details',
   status_awaiting_confirmation_from_db: "waiting confirmation from DB",
   status_offer_booked: "Offer booked",
   status_offer_confirmed: "Offer confirmed",
   status_offer_confirmed_description: "offer confirmed description",
   trip_detail_access: "Access QR-Code",
   status_trip_requested_description: "trip requested description",
   status_trip_requested_without_responsible_description: "trip requested without responsible description",
   status_offer_pending_description: "offer pending description",
   status_offer_booked_description: "offer booked description",
   status_on_trip_description: "on trip description",
   status_on_trip: "On trip",
   status_trip_requested: "Trip requested",
   status_offer_number_pending: "Offer pending",
   status_awaiting_trip_advisor: "waiting for trip advisor",
   status_awaiting_offer_from_db: "awaiting offer from DB",
   status_awaiting_for_your_booking: "waiting for your booking",
   status_items_pending_approval_from_db: "items pending approval from db",
   participant_summary_clarfication: 'participant_summary_clarfication',
   status_items_pending_approval_from_db_description: 'items pending approval',
   documents_tooltip_text: 'documents tooltip text',
   documents_completed: 'document info completed',
   continue_and_reject_existing_offer: 'continue and reject previous offer',
   you_have_a_pending_offer: 'you have a pending offer',
   add_program_and_reject_pending_offer_modal_text: 'add program and reject pending offer modal text',
   consent_title: 'consent title',
   consent_modal_text: 'For the maps functionality we use google maps. <br>So in order to show you the map, you need to accept the use of google maps.<br>For remembering your preferences regarding google maps, we store a cookie only with that information.',
   review_meal_plans: 'review meal plans',
   status_offer_expired_description: 'status offer expired description',
   decline: 'decline',
   status_offer_expired: 'status offer expired',
   request_a_new_one: 'request a new one',
   required_field_clarification: 'Mandatory fields<br>For completion and transmission to DB Klassenfahrten und Gruppenreisen, it is necessary to fill in the mandatory fields',
   accept: 'accept',
   offer_already_expired_modal_input_placeholder: 'offer already expired modal input placeholder',
   send_request: 'send request',
   offer_already_expired_modal_description: 'offer already expired modal description',
   offer_already_expired_modal_title: 'offer already expired modal title',
   waiting_for_new_one: 'waiting for new one',
   new_offer_request_sent: 'new offer request sent',
   could_not_send_the_request: 'could not send the request',
   awaiting_partner_confirmation: 'awaiting confirmation',
   awaiting_partner_confirmation_explanation: 'The program is booked, but we need to wait until we can confirm the program with the partner',
   documents_missing: 'documents missing',
   programs_waiting_partner_confirmation_text: 'some programs are waiting for confirmation',
};