import dashboardService from '@/services/dashboard.js';

export default {

   state: () => ({
      filteredPrograms: [],
      mapItems: null,
      selectedProgram: null,
      selectedExtras:[],
      showAddProgramModal: false,
      showPendingOfferWarningModal: false,
      showCookiesDetailsModal: false,
      showProgramInfoModal: false,
      showRequestNewOfferModal: false,
      suggestedPrograms: [],
      timeline: null,
   }),

   getters: {
      haveProgramsToDisplay: state => state.filteredPrograms.length > 0,
   },

   actions: {

      addProgramToTrip({ commit, dispatch, rootState }, payload) {
         payload['isDB'] = rootState.isDb;

         return dashboardService.addProgram(payload)
            .then(() => {
               dispatch('refreshTimelineData');
               commit('resetSelectedExtras');
               commit('setAddProgramModalVisibility', false);

               if (
                  rootState.tripStatus == 'TRIP_DETAIL_STATUS_OFFER_AWAITING' ||
                  rootState.tripStatus == 'TRIP_DETAIL_STATUS_OFFER_CONFIRMED' ||
                  rootState.tripStatus == 'TRIP_DETAIL_STATUS_TRIP_ASSIGNED_DB_USER')
               {
                  commit('setTripStatus', 'TRIP_DETAIL_STATUS_ITEMS_PENDING_APPROVAL');
               }
            })
            .catch(() => dispatch('displayError', { summary: 'error', detail: 'could_not_add_program' }));
      },

      refreshTimelineData({ commit, dispatch }) {

         commit('setPageLoading', true);
         return dashboardService.fetchTimelineData()
            .then(response => commit('setTimelineData', response['result']))
            .catch(() => dispatch('displayError', { summary: 'error', detail: 'could_not_add_program' }))
            .finally(() => commit('setPageLoading', false));
      },

      requestNewOffer({ commit, dispatch }, payload) {

         commit('setRequestNewOfferLoading', true);
         return dashboardService.requestNewOffer(payload)
            .then(() => {
               dispatch('displayMessage', { summary: 'success', detail: 'new_offer_request_sent' });
               commit('setOfferRebookRequested', true);
            })
            .catch(() => dispatch('displayError', { summary: 'error', detail: 'could_not_send_the_request' }))
            .finally(() => commit('setRequestNewOfferLoading', false));
      },
   },

   mutations: {
      resetSelectedExtras(state) {
         state.selectedExtras = [];
      },

      setDashboardData(state, data) {
         state.mapItems = [...data.mapItems];
         state.timeline = [...data.timeline];
         state.filteredPrograms = [...data.suggestedPrograms];
         state.suggestedPrograms = [...data.suggestedPrograms];
         state.selectedProgram = data.suggestedPrograms.length
            ? data.suggestedPrograms[0]
            : null;
      },

      setTimelineData(state, data) {
         state.timeline = [...data];
      },

      setAddProgramModalVisibility(state, visibility) {
         state.showAddProgramModal = visibility;
      },

      setPendingOfferWarningModalVisibility(state, visibility) {
         state.showPendingOfferWarningModal = visibility;
      },

      setCookiesDetailModalVisibility(state, visibility) {
         state.showCookiesDetailsModal = visibility;
      },

      setRequestNewOfferModalVisibility(state, visibility) {
         state.showRequestNewOfferModal = visibility;
      },

      setSelectedProgram(state, program) {
         state.selectedProgram = program;
      },

      setProgramInfoModalVisibility(state, visibility) {
         state.showProgramInfoModal = visibility;
      },
      
      setSelectedProgramInfo(state, info) {
         state.selectedProgram = Object.assign(state.selectedProgram, { info });
      },

      setFilteredPrograms(state, programs) {
         state.filteredPrograms = [...programs];
      },
   },

}