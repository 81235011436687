import 'dotenv/config';
import axios from 'axios';
import store from '../store/index.js';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

function call(url, method, data = {}) {
    return axios({
        method,
        url: BASE_URL + url,
        data,
        headers: {
            "Access-Token": store.getters.accessToken,
            //"Access-Token": "CfY3RUKfRXiamFkn"
        }
    })
    .then(response => response.data)
    .catch(error => {
        if (error.response.status == 401) {
            store.commit('setUnauthorizedAccess');
            // redirect to dashboard
        }

        return Promise.reject(error);
    });
}

function get(url, options = {}) {
    return call(url, 'get', options);
}

function post(url, data) {
    return call(url, 'post', data);
}

function put(url, data) {
    return call(url, 'put', data);
}

function destroy(url) {
    return call(url, 'delete');
}

export default {
    get,
    post,
    put,
    destroy
};