/**
 * @docs https://developers.google.com/maps/documentation/javascript/overview
 */

import { mapGetters } from 'vuex';

export default {
   data() {
      return {
         map: null,
         viewPortSet: false,
         infoWindow: null,
         centerLocation: null,
         markers: [],
         bounds: [],
      }
   },
   computed: {
      ...mapGetters([
         'hasConsented',
      ]),
   },
   methods: {
     /**
      * Initialize the instance of Google Maps defined on Dashboard.vue
      */
      initGoogleMaps(mapCanvas) {
         // Create map element, set center & zoom
         if (window.google != 'undefined') {

            this.bounds = new google.maps.LatLngBounds();

            // Deutsche Bahn AG Konzernzentrale as default center
            this.centerLocation = new google.maps.LatLng(52.5096436, 13.3728768);

            this.infoWindow = new google.maps.InfoWindow();

            this.map = new google.maps.Map(
               mapCanvas,
               { center: this.centerLocation, zoom: 15 }
            );
         }
      },
      injectGoogleScriptIntoTheDOM() {
         var head = document.getElementsByTagName('head')[0];
         var mapApiJs = document.createElement("script");
         mapApiJs.setAttribute("type", "text/javascript");
         mapApiJs.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=AIzaSyAKxqid9-vjTbbutM717BctdTM9LzV3cuM&libraries=places&language=de");
         head.appendChild(mapApiJs);
      },
      /**
       * Adds a marker on the Google map
       *
       * @param {Object} location - contains latitude and longitude
       * @param {String} text - the marker text (hotel name)
       * @param {Boolean} visible - the marker show be shown or not
      */
      createMarker(location, text, visible) {

         let markerData = { map: this.map, position: location, visible: visible };

         if (text) {
            markerData.label = { text: text, className: 'map-marker-label' };
            markerData.optimized = false;
         } else {
            console.warn('[Map mixin] Missing mapCoordinates title attribute');
         }

         let marker = new google.maps.Marker(markerData);

         this.markers.push(marker);
      },
      /**
      * Uses the lat and lng we already have to set the map center & marker
      *
      * @ex mapCoordinates = {
      *     0 : { 'lat': .. , 'lng': .. }, ..
      * }
      *
      */
      setMapFromSavedLatLng(mapCoordinates) {
         mapCoordinates.forEach(mapCoordinate => {
            if (mapCoordinate.lat && mapCoordinate.lng) {
               let lat = mapCoordinate.lat;
               let lng = mapCoordinate.lng;
               try {
                  lat = parseFloat(lat);
                  lng = parseFloat(lng);
                  let newLocation = { 'lat': lat, 'lng': lng };

                  const visible = !!(mapCoordinate.show_map);
                  this.createMarker(newLocation, mapCoordinate.title, visible);

                  // extend the bounds to include each marker's position
                  this.bounds.extend(newLocation);

               } catch (error) {
                  // it's expected to fail if the user set the wrong data
                  console.warn('[Map mixin] unparseable lat / lng', error);
               }
            }
         });
      },
      // wait until the map is resized by css
      setViewPort() {
         // Sets the viewport to contain the given bounds.

         // Avoid rendering max zoom when only one marker exists
         var extendPoint1 = new google.maps.LatLng(this.bounds.getNorthEast().lat() + 0.01, this.bounds.getNorthEast().lng() + 0.01);
         var extendPoint2 = new google.maps.LatLng(this.bounds.getNorthEast().lat() - 0.01, this.bounds.getNorthEast().lng() - 0.01);
         this.bounds.extend(extendPoint1);
         this.bounds.extend(extendPoint2);
         this.map.fitBounds(this.bounds);
      },
      renderMap() {

         if (!this.$refs.hasOwnProperty('map_canvas')) {
            console.warn('[Map mixin] Missing map_canvas reference');
            return;
         }

         this.initGoogleMaps(this.$refs.map_canvas);

         if (!this.mapCoordinates) {
            console.warn('[Map mixin] Missing mapCoordinates attribute');
            return;
         }

         this.setMapFromSavedLatLng(this.mapCoordinates);

         if (this.mapWaitForEvent) {

            // sometimes waiting is required because the viewport is affected by
            // the current size of the map canvas, if it changes after setting
            // a viewport the map wont be properly centered/zoomed for all marks
            this.$on(this.mapWaitForEvent, () => {
                this.setViewPort();
            });
         } else {
            // wait a little
            setTimeout(this.setViewPort(), 5000);
         }
      },
      initAndLoadMaps() {
         this.injectGoogleScriptIntoTheDOM();
         setTimeout(this.renderMap, 2000);
      },
   },
   mounted() {
      if (!this.hasConsented) {
         return;
      }

      this.renderMap();
   }
}
