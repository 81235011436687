<template>
   <div class="b-footer">
      <div class="b-footer__logo-wrap">
         <!-- <img src="@/assets/img/db-iso.svg" class="b-footer__logo" /> -->
         <div class="b-footer__logo"></div>

         <a
            href="https://www.bahn.de/datenschutz" 
            class="b-footer__data-privacy" 
            target="_blank"
         >
            {{ $t('footer_data_privacy') }}
         </a>

         <a
            href="https://www.bahn.de/impressum" 
            class="b-footer__data-privacy" 
            target="_blank"
         >
            Impressum
         </a>
      </div>

      <div class="b-footer__separator">
      </div>

      <div class="b-footer__contact-item icon__phone">
         {{ responsiblePhone }}
      </div>

      <div class="b-footer__contact-item icon__mail">
         DB-Klassenfahrten@deutschebahn.com
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      computed: {
         ...mapState([
            'responsible'
         ]),
         responsiblePhone() {
            if (!this.responsible || !this.responsible.phone) {
               return this.$t('db_partner_hotline');
            }

            return this.responsible.phone;
         }
      },
   }
</script>

<style lang="less">
   .b-footer {
      background-color: var(--color-gray);
      color: white;
      padding: 15px 30px 15px var(--side-menu-width);
      .h-flex-row-left-center;

      &__logo-wrap {
         gap: 15px;
         .h-flex-row-left-center;
      }

      &__logo {
         font-size: calc((65 / var(--base-value)) * 1rem);
         .icon(db-logo);
      }

      &__data-privacy {
         font-size: var(--base);
         opacity: 0.6;
         text-decoration: underline;
         color:white;
         .transition;

         &:hover {
             opacity: 1;
         }
      }

      &__separator {
         .flex-grow(1);
      }

      &__contact-item {
         font-size: calc((16 / var(--base-value)) * 1rem);
         margin-left: 30px;

         &:before {
            margin-right: 7px;
         }
      }

      @media only screen and (max-width : @breakpoint-md) {
         .flex-direction(column-reverse);

         &__contact-item {
            margin: 0 0 10px 0;
         }
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         padding-left: var(--page-padding);
      }
   }
</style>