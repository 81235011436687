<template>
   <SummaryItem icon="location">
      <template v-for="(destination, index) in destinations.all" :key="destination.name">

         <i class="h-arrow" v-if="notFirstOrLastDestination(index)"></i>

         <img
            class="h-flag"
            :src="destination.region.country.flag"
            :alt="destination.name"
         >

         <div class="b-summary-item__main-text"> {{ destination.name }} </div>
      </template>
   </SummaryItem>
</template>

<script>
   import { mapState } from 'vuex';
   import SummaryItem from '@/components/shared/SummaryItem'

   export default {
      components: { SummaryItem },
      computed: {
         ...mapState([
            'destinations'
         ]),
      },
      methods: {
         notFirstOrLastDestination(index){
            return index != 0 && index != this.destinations.all.length;
         },
      }
   }
</script>

<style scoped>
   >>> .b-summary-item__icon {
      position: relative;
      top: 3px;
   }
</style>
