<template>
   <div 
      class="b-warning-message" 
      :class="{ 
         'is-clickable' : isClickable, 
         'is-compact' : isCompact,
         'is-program-warning' : 'isProgramWarning'
      }"
   >
      <div class="b-warning-message__inner">
         <div
            class="b-warning-message__title"
            :class="`icon__${icon}`"
         >
            {{ title }}
         </div>

         <div class="b-warning-message__text">
            <slot></slot>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      props: {
         icon: {
            type: String,
         },
         title: {
            type: String,
         },
         isClickable: {
            type: Boolean,
            default: false,
         },
         isCompact: {
            type: Boolean,
            default: false,
         },
         isProgramWarning: {
            type: Boolean,
            default: false,
         }
      },
   }
</script>

<style lang="less">
   .b-warning-message {
      text-align: center;

      &__inner {
         display: inline-block;
         background-color: var(--color-red);
         color: white;
         padding: 15px 20px;
         border-radius: 7px;
         .h-font-regular;
         .transition;
      }

      &__title {
         line-height: 1;
         margin-bottom: 4px;
         font-size: calc((16 / var(--base-value)) * 1rem);
         .h-flex-row-left-center;

         &:before { // icon
            font-size: calc((25 / var(--base-value)) * 1rem);
            margin-right: 10px;
            line-height: 1;
         };
      }

      &__text {
         line-height: 1;
      }

      &.is-clickable {
         .b-warning-message__inner {
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
               background-color: var(--color-red-hover);
            }
         }
      }

      &.is-compact {
         .b-warning-message__inner {
            background-color: hsl(var(--color-red-hs) 90%);
            padding: 10px;
            border-radius: 5px;
         }

         .b-warning-message__title {
            font-size: var(--base);
            color: var(--color-gray);

            &:before { // icon
               opacity: 0.7;
               font-size: calc((16 / var(--base-value)) * 1rem);
            };
         }
      }

      &.is-program-warning {
         .b-warning-message__inner {
            background-color: var(--color-gray-4);
         }
      }
   }
</style>