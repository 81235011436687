<template>
   <div
      class="b-timeline-item"
      :class="{
         'is-transportation' : isTransportation(),
         'is-accomodation' : isAccommodation()
      }"
   >
      <!-- line & dot -->
      <div class="b-timeline-item__left">
         <div class="b-timeline-item__line"></div>
         <div class="b-timeline-item__dot"></div>
      </div>

      <div class="b-timeline-item__right">
         <!-- icon -->
         <div
            v-if="!item.isDestination"
            class="b-timeline-item__icon"
            :class="icon()"
         >
         </div>

         <!-- content -->
         <slot></slot>
      </div>
   </div>
</template>

<script>
   import { typeName as serviceName} from '@/constants/service.js';

   export default {
      props: {
         item: null,
      },
      methods: {
         isTransportation() {
            return this.item.type == serviceName.TRANSPORTATION;
         },
         isAccommodation() {
            return this.item.type == serviceName.ACCOMMODATION;
         },
         isProgram() {
            return this.item.type == serviceName.PROGRAM;
         },
         icon() {
            let iconPrefix = ' icon__';
            switch (this.item.type) {
               case serviceName.TRANSPORTATION:
                  return `${iconPrefix}${this.item.mean}-full`;
               case serviceName.ACCOMMODATION:
                  return `${iconPrefix}service-bell-full`;
               case serviceName.PROGRAM:
                  return `${iconPrefix}camera-full`;
            }
         },
     },
   };
</script>

<style lang="less">
   .b-timeline-item {
       @top-spacing: 13px;
       @dot-size: 12px;

       .h-flex-row-left-top;
       .align-items(stretch);

       &__left {
           width: @dot-size;
           position: relative;
           margin-right: var(--page-padding);
           .h-flex-col-center;
           .align-items(center);
           .justify-content(flex-start);
           .flex-shrink(0);
       }

       &__right {
           padding: @top-spacing @top-spacing @top-spacing 0;
           font-size: calc((16 / var(--base-value)) * 1rem);
           max-width: calc(~"100% - var(--page-padding)"); // hack to avoid overflow when using the ellipsis for the title
           .h-flex-row-left-top;
       }

       &__line {
           width: 2px;
           background-color: var(--color-gray-2);
           height: 100%;
       }

       &__dot {
           display: none;
           width: @dot-size;
           height: @dot-size;
           background-color: var(--color-gray-2);
           position: absolute;
           left: 50%;
           top: 50%;
           border-radius: 100%;
           .transform(translateX(-50%) translateY(-50%));
       }

       &__icon {
           font-size: calc((23 / var(--base-value)) * 1rem);
           color: var(--color-green);
           margin-right: 10px;
           width: 30px;
           position: relative;
           top: -3px;
           .flex-shrink(0);
       }

       &__date,
       &__time {
           color: var(--color-gray-3);
           font-size: calc((14 / var(--base-value)) * 1rem);
           .h-font-light;
           .flex-shrink(0);
       }

       &__date {
           &:not(.has-no-margin):before {
               content: '-';
               margin: 0 5px;
           }

           &.is-program-date {
               color: var(--color-gray-2);
           }
       }

       &__time {
           margin-left: 5px;
           font-size: calc((13 / var(--base-value)) * 1rem);

           &:before {
               content: '- ';
           }
       }

       &__alternative-warning {
           font-size: calc((12 / var(--base-value)) * 1rem);
           background-color: fade(@status-color-3, 10%);
           display: inline-block;
           color: var(--color-red);
           padding: 4px 7px;
           line-height: 1;
           .h-font-light;

           &:hover {
               color: var(--color-red);
           }
       }

       &__title-outter {
           .h-flex-row-left-center;

           &:before {
               content: '';
               width: 5px;
               height: 5px;
               background-color: var(--color-green);
               border-radius: 100%;
               margin-right: 7px;
               .flex-shrink(0)
           }
       }

       &__title {
           text-overflow: ellipsis;
           white-space: nowrap;
           overflow: hidden;
       }

       .h-awaiting-partner-booking {
           margin-left: 15px;
       }

       // statuses
       &.is-destination {
           .h2-style;

           .b-timeline-item__dot {
               display: block;
           }

           .b-timeline-item__right {
               font-size: calc((19 / var(--base-value)) * 1rem);
               line-height: 1;
               padding-bottom: 0;
           }

           .b-timeline-item__date {
               font-size: calc((16 / var(--base-value)) * 1rem);
               position: relative;
               top: 4px;
           }
       }

       &.is-transportation {
           .b-timeline-item__dot {
               display: block;
               border: solid 2px var(--color-gray-2);
               background-color: white;
           }
       }

       &.is-accomodation {
           .h-font-bold;
       }

       &.is-start-point,
       &.is-end-point {
           .b-timeline-item__line {
               height: 50%;
           }
       }

       &.is-start-point {
           .b-timeline-item__left {
               .justify-content(flex-end);
           }
       }

       &.is-destination.is-start-point,
       &.is-destination.is-end-point {
           .b-timeline-item__right {
               padding-bottom: @top-spacing;
           }
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           &__title-outter {
               .flex-wrap(wrap);
           }
       }
   }
</style>