/*             all date manipulation functions reside here           */
import dayjs from 'dayjs';
import { formats, weekdaysArray } from '../constants/date.js';

require('dayjs/locale/de');
const weekday = require('dayjs/plugin/weekday');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
var customParseFormat = require("dayjs/plugin/customParseFormat");
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
// dayjs.extend(locale);

export default class DateHelper {

   static addMinutes(time, minutesToAdd) {
      return dayjs(time, formats.TIME).add(minutesToAdd, 'minute').format(formats.TIME);
   }

   static format(dateString, inputFormat = formats.DATABASE, outputFormat = formats.DISPLAY) {
      return dayjs(dateString, inputFormat).locale('de').format(outputFormat);
   }

   static toISOstring(dateString, inputFormat = formats.DATABASE) {
      return dayjs(dateString, inputFormat).toISOString();
   }

   static fromNow(date) {
      return dayjs(date).fromNow();
   }

   /* returns weekday name in english e.g "monday" */
   static getWeekDay(date, inputFormat = formats.DISPLAY) {
      const weekDayInt = dayjs(date, inputFormat).get('day');

      return weekdaysArray[weekDayInt];
   }

   static now(format = formats.DISPLAY) {
      return dayjs().locale('de').format(format);
   }

   // available locales: de, en
   static setLocale(aLocale) {
      dayjs.locale(aLocale);
   }

}