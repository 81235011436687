<template>
   <div class="b-meal-plans">
      <collapsible>
         <!-- title -->
         <template v-slot:trigger>
            <div class="b-meal-plans__title-wrap">
               <i class="b-meal-plans__title-icon"></i>

               <div class="b-meal-plans__title">
                  {{ $t('review_meal_plans') }}
               </div>
            </div>
         </template>

         <div class="h-collapsible-target">
            <!-- hotel tabs  -->
            <!-- the ScrollPanel height is fixed to avoid vertical scroll -->
            <ScrollPanel style="width: 100%; height: 50px;">
               <div class="h-tab-nav">
                  <div
                     v-for="(hotel, index) in hotels"
                     :key="index"
                     @click="selectHotel(hotel.id)"
                     class="h-tab-nav__item"
                     :class="{ 'is-active' : selectedHotel == hotel.id }"
                  >
                     <div class="h-tab-nav__item-main">
                        {{ hotel.name }}
                     </div>
                  </div>
               </div>
            </ScrollPanel>

            <template v-for="(hotel, index) in hotels" :key="index">
               <div v-if="selectedHotel == hotel.id" class="b-meal-plans__hotel-wrap">
                  <div style="max-width: 100%;overflow: auto;">
                     <!-- table -->
                     <MealPlanTable
                        :key="mealPlanTableKey"
                        :tripDays="tripDays"
                        :mealPlansProp="hotelMealPlans(selectedHotel)"
                        @addMealToRequest="addMealToRequest"
                        @removeMealFromRequest="removeMealFromRequest"
                        :disabled="!isMealplanEditable"
                     />
                  </div>

                  <!-- request -->
                  <transition name="fade-and-slide">
                     <div v-if="isRequestBlockVisible" class="b-meal-plans__request-wrap">
                        <div v-if="isRequestBlockVisible" class="b-meal-plans__request-wrap">

                           {{ $t("request_comments") }}
                           <br>

                           <Textarea
                               v-model="requestComment"
                               :placeholder="$t('want_to_enter_comment')"
                               :autoResize="true"
                           />

                           <div class="h-input-block__label"></div>

                           <div class="b-meal-plans__request-bottom">
                              <div class="b-meal-plans__request-bottom-clarification">
                                 {{ $t('warning_db_will_be_notified') }}
                              </div>

                              <div class="b-meal-plans__request-buttons-wrap">
                                 <span @click="resetRequest" class="h-cancel">
                                    {{ $t('cancel') }}
                                 </span>

                                 <Button @click="sendRequest" :label="$t('update_meal_plans')"/>
                              </div>
                           </div>

                           <VueElementLoading :active="isLoading" spinner="bar-fade-scale" color="#f00f0f"/>
                        </div>
                     </div>
                  </transition>
               </div>
            </template>
         </div>
      </collapsible>
   </div>
</template>

<script>
   import _ from 'lodash';
   import moment from 'moment';
   import { mapState, mapActions } from 'vuex';
   import MealPlanTable from '@/components/trip_details/MealPlanTable.vue';
   import VueElementLoading from 'vue-element-loading';
   import mealService from '@/services/meal.js';
   import Collapsible from '@/components/shared/Collapsible.vue';
   import Textarea from 'primevue/textarea';
   import ScrollPanel from 'primevue/scrollpanel';
   import { message, error } from '../../constants/toastMessages.js';

   export default {
      components: {
         Collapsible,
         MealPlanTable,
         Textarea,
         VueElementLoading,
         ScrollPanel,
      },
      data() {
         return {
            isLoading: false,
            isRequestBlockVisible: false,
            requestComment: '',
            mealPlanTableKey: true,
            request: {},
            selectedHotel: 0,
         }
      },
      props: {
         isMealplanEditable: {
            type: Boolean,
            default: true,
         },
      },
      computed: {
         ...mapState([
            'mealPlanData',
         ]),
         hotels() {
            if (!this.mealPlanData) {
               return null;
            }

            return this.mealPlanData.hotels;
         },
         tripDays() {
            return this.mealPlanData.tripDatePeriod;
         },
         mealPlans() {
            if (!this.mealPlanData) {
               return null;
            }

            return this.mealPlanData.data;
         },
      },
      methods: {
         ...mapActions([
            'refreshMealPlanData',
            'displayMessage',
            'displayError'
         ]),
         addMealToRequest(meal, date) {
            if (this.request[meal].serviceDates.includes(date)) {
               return;
            }
            this.isRequestBlockVisible = true;
            this.pushDateInOrder(this.request[meal].serviceDates, date)
         },
         getFullDate(date) {
            if (!date) {
               return null;
            }

            return this.mealPlanData.fullTripDates
                 .find(fullDate => moment(fullDate).format('DD.MM') == date);
         },
         hotelMealPlans(hotelId) {
            if (!this.mealPlanData) {
               return null;
            }

            return this.mealPlans[hotelId].mealPlans;
         },
         initRequestObject() {
            if (!this.mealPlanData) {
               return null;
            }

            this.request = {};
            this.hotelMealPlans(this.selectedHotel).forEach(meal => {
            this.request[meal.id] = {
                  name: meal.name,
                  serviceDates: [...meal.serviceDates],
               }
            });
         },
         pushDateInOrder(dateArray, date) {
            let length = dateArray.length;

            if (length == 0) {
               dateArray.push(date);
               return;
            }

            dateArray.every((serviceDate, index) => {

            if (moment(this.getFullDate(date)).isBefore(this.getFullDate(serviceDate))) {
               dateArray.splice(index, 0, date);
               return;
            } else if(!dateArray[index + 1]) {
               dateArray.push(date);
               return;
            }
               return true;
            });
         },
         resetRequest() {
            this.requestComment = '';
            this.initRequestObject();
            this.isRequestBlockVisible = false;
            this.mealPlanTableKey = !this.mealPlanTableKey;
         },
         removeMealFromRequest(meal, date) {
            this.isRequestBlockVisible = true;
            let updatedServiceDates = this.request[meal].serviceDates
               .filter(serviceDate => serviceDate != date);

            this.request[meal].serviceDates = updatedServiceDates;
         },
         requestPayload() {
            let payload = {
               mealPlans: [],
               hotelId: this.selectedHotel,
               comment: this.requestComment,
            };

            for (const mealId in this.request) {

               let mealPlan = this.request[mealId];

               if (this.wasUpdated(mealPlan)) {

                  payload.mealPlans.push({
                     id: mealId,
                     dates: mealPlan.serviceDates,
                     positionId: this.selectedHotel,
                     minDate: this.getFullDate(mealPlan.serviceDates[0]),
                     maxDate: this.getFullDate(mealPlan.serviceDates[mealPlan.serviceDates.length - 1]),
                  });
               }
            }

            return payload;
         },
         selectHotel(id) {
            this.selectedHotel = id;
            this.resetRequest();
         },
         sendRequest() {
            this.toggleLoading();
            mealService.sendRequest(this.requestPayload())
               .then(() => {
                  this.refreshMealPlanData().then(this.resetRequest);

                  this.displayMessage(message.MEALPAN_STORE_SUCCESS);

                  this.toggleLoading();
               }).catch(() => {
                  this.displayError(error.MEALPLAN_STORE_FAIL);
                  this.toggleLoading();
                  this.resetRequest();
               });
         },
         toggleLoading() {
            this.isLoading = !this.isLoading;
         },
         wasUpdated(meal) {
            let originalMealPlan = this.hotelMealPlans(this.selectedHotel)
               .find(someMeal => someMeal.name == meal.name);

            return !_.isEqual(meal.serviceDates, originalMealPlan.serviceDates);
         },
      },
      created() {
         if (!this.hotels) {
            return null;
         }
         this.selectHotel(this.hotels[0].id);
      },
      mounted() {
         this.initRequestObject();
      }
   };
</script>

<style lang="less">
    .b-meal-plans {
        margin-top: var(--page-block-separation);
        .h-white-block;
        padding-bottom: 15px;

        &__title-wrap {
            .h-flex-row-left-center;
        }

        &__title-icon {
            font-size: calc((25 / var(--base-value)) * 1rem);
            color: var(--color-red);
            .icon(meal);
        }

        &__title {
            font-size: calc((16 / var(--base-value)) * 1rem);
            margin: 0 10px;
            line-height: 1;
        }

        &__collapse-arrow {
            font-size: calc((10 / var(--base-value)) * 1rem);
            color: var(--color-gray-3);
            .icon(arrow-down);
        }

        &__hotel-wrap {
            background-color: var(--color-gray-light);
            padding: 30px 15px 15px;
            margin-bottom: 20px;
            min-height: 175px;
            .h-flex-row-left-top;
        }

        &__request-wrap {
            .flex-grow(1);
        }

        &__request-bottom {
            margin-top: 20px;
            text-align: right;
        }

        &__request-buttons-wrap {
            margin-top: 7px;
            .h-flex-row-right-center;
        }

        &__request-bottom-clarification {
            color: var(--color-gray-2);
            font-size: calc((13 / var(--base-value)) * 1rem);
        }

        .h-tab-nav {
            &__item {
               .flex-shrink(0);
            }

            &__item-main {
               font-size: calc((16 / var(--base-value)) * 1rem);
            }

            &__item.is-active {
               background-color: var(--color-gray-light) !important;
            }
        }

        .b-meal-plan-table {
            margin-right: 50px;
        }

        .p-scrollpanel {
            margin-top: 20px;

            .p-scrollpanel-bar { // hide scrollbar
                display: none !important;
            }

            .p-scrollpanel-content { // remove padding to avoid weird scroll behaviour in firefox
                padding-bottom: 0;
            }
        }

        @media only screen and (max-width : 992px) {
            &__hotel-wrap {
                .h-flex-col;
            }

            &__request-wrap {
                margin-top: 30px;
                width: 100%;
            }

            .b-collapsible__content {
                height: auto !important;
            }
        }
    }
</style>