<template>
   <div
      v-show="hasConsented"
      ref="map_canvas"
      style="min-height:400px;"
      class="h-shadow"
   >
   </div>

   <div v-if="!hasConsented" class="b-no-consent-map">
      <div class="b-no-consent-map__content">
         {{ $t('accept_cookies_explaination') }}

         <span class="h-link" @click="onAddClick">
            {{ $t('see_more_details') }}
         </span>

         <Button
            class="h-margin-top-15"
            :label="$t('accept_the_use_of_google_maps')"
            @click="onAcceptAllCookiesClick"
         />
      </div>
   </div>

   <VueElementLoading :active="loading" spinner="bar-fade-scale"/>
</template>

<script>
   import Button from 'primevue/button';
   import maps from '@/mixins/googleMaps.js';
   import VueElementLoading from 'vue-element-loading';
   import { mapState, mapGetters, mapMutations } from 'vuex';

   export default {
      components: {
         Button,
         VueElementLoading,
      },
      mixins: [ maps ],
      data() {
         return {
            mapWaitForEvent: false,
            loading: false,
         };
      },
      computed: {
         ...mapState({
            mapCoordinates: state => state.dashboard.mapItems,
         }),
         ...mapGetters([
            'hasConsented',
         ]),
      },
      methods: {
         ...mapMutations([
            'setConsent',
            'setCookiesDetailModalVisibility',
         ]),
         onAcceptAllCookiesClick() {
            this.loading = true;
            this.setConsent(true);
            this.initAndLoadMaps();
            setTimeout(() => this.loading = false, 2000);
         },
         onAddClick() {
            this.setCookiesDetailModalVisibility(true);
         },
      },
      created() {
         this.loading = true;
         setTimeout(() => this.loading = false, 500);
      }
   };
</script>

<style lang="less">
   .b-no-consent-map {
        position: relative;
        min-height: 130px;
        text-align: center;
        background-color: white;
        .h-flex-col-center;
        .align-items(center);
        .h-shadow;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('./../../assets/img/dummy-map.jpeg');
            opacity: 0.3;
            pointer-events: none;
        }

        &__content {
           position: relative;
           .h-flex-col-center;
        }

        .p-button {
            margin-top: 20px !important;
        }
    }
</style>