<template>
   <template v-if="status && tripStatus && show">
      <div class="b-trip-status-guide-item" :class="itemClasses">
         <div class="b-trip-status-guide-item__top">
            <span class="b-trip-status-guide-item__main">
               {{ $t(status.mainText) }}
            </span>
            <span class="b-trip-status-guide-item__secondary">
               {{ $t(status.secondaryText) }}
            </span>
         </div>

         <div class="b-trip-status-guide-item__description" v-html="$t(status.description)">
         </div>
      </div>
   </template>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      props: {
         status: {
            type: Object,
            required: true,
         },
         hasLine: {
            type: Boolean,
            default: false,
         },
         show: {
            type: Boolean,
            default: true,
         },
         tripStatusClass: {
            type: String
         },
      },
      computed: {
         ...mapState([
            'tripStatus'
         ]),
        //  tripStatusClass() {
        //     return 'is-status-' + this.tripStatus.prefix;
        //  },
         itemClasses() {
            let classes = [''];

            if (!this.status) {
               return classes;
            }

            let statusItemClass = 'is-status-' + this.status.prefix;

            classes.push(statusItemClass);

            if (this.tripStatusClass == statusItemClass) {
               classes.push(' is-active');
            }

            if (this.hasLine) {
               classes.push(' has-line');
            }

            return classes;
         },
      },
   };
</script>

<style lang="less">
   .b-trip-status-guide-item {
       --color: black;

       border-color: var(--color);
       border-style: none;
       border-width: 2px;
       position: relative;
       padding: 10px 15px 10px 30px;
       border-radius: 10px;
       margin-top: 5px;

       &:after { // dot
           content: '';
           width: 12px;
           height: 12px;
           border-radius: 100%;
           background-color: var(--color);
           position: absolute;
           top: 14px;
           left: 10px;
       }

       &.has-line {
           &:before {
               content: '';
               width: 1px;
               height: calc(~"100% + 8px");
               background-color: var(--color-gray-3);
               position: absolute;
               top: 16px;
               left: 15px;
           }
       }

       &__top {
           font-size: 15px;
       }

       &__main {
           margin-right: 10px;
           .h-font-bold;
       }

       &__description {
           font-size: var(--base);
           color: var(--color-gray-2);

           a {
               color: var(--color-red);

               &:hover {
                   text-decoration: underline;
               }
           }
       }

       // statuses
       &.is-active {
           border-style: solid;
           background-color: white;

           &:before { // line
               left: 14px;
           }
       }

       &.is-status-trip-requested,
       &.is-status-trip-requested-db,
       &.is-status-processing-request,
       &.status-2, {
           --color: @status-color-1;
       }
       &.is-status-offer-awaiting,
       &.is-status-offer-expired {
           --color: @status-color-3;
       }
       &.is-status-offer-rejected {
           --color: @status-color-5;
       }
       &.is-status-offer-booked {
           --color: @status-color-6;
       }
       &.is-status-offer-confirmed,
       &.is-status-on-trip {
           --color: @status-color-7;
       }
       &.is-status-items-pending-approval,
       &.is-status-items-pax-updated {
           --color: @status-color-8;
       }
       &.is-status-trip-canceled {
           --color: @status-color-11;
       }
       &.is-status-trip-archived  {
           --color: @status-color-12;
       }

       &.has-line:last-child {
           &:before { // remove line
               opacity: 0;
           }
       }
   }
</style>