<template>
   <template v-if="onParticipantListPage">
      <iframe id="exportFrame" style="display:none"></iframe>
      <span
         :title="$t('excel_export_tooltip')"
         class="b-header-participant-btn icon__send"
         @click="excelExport"
      >
      </span>
      <span
         class="b-header-participant-btn icon__printer"
         @click="goToParticipantsPrintView"
      >
      </span>
   </template>
</template>

<script>
   import navigation from '@/mixins/navigation.js';
   import {mapState} from 'vuex';
   export default {
      mixins: [ navigation ],
      computed: {
         ...mapState({
            participantsArray: state => state.participantList.participants,
            countries: state => state.countries
         })
      },
      methods: {
         excelExport() {
         let excludedColumns = ['room', 'id', 'process_id', 'updationInfo', 'originalParticipantData','is_vaccinated','is_healthy'];
         let participantsArray = this.participantsArray;
         let exportString = "<table border='2px'>";
         for (let i = 0; i < participantsArray.length; i++) {
            // Adding table headers
            if (i == 0) {
               for (const [key] of Object.entries(participantsArray[i]))
               {
                     if (excludedColumns.includes(key)) {
                        continue;
                     }
                     let translatedKey = this.$t(key);
                     exportString += "<th bgcolor='#87AFC6'>" +
                        translatedKey + "</th>";
               }
            }

            let bgcolor = this.isEven(i) ? '#ffffff' : '#e8eaed';
            exportString += '<tr bgcolor=' + bgcolor + '>';

            for (const [key, value] of Object.entries(participantsArray[i])) {

               if (excludedColumns.includes(key)) {
                     continue;
               }

               exportString += '<td>';

               exportString += this.getFormatedPropertyValue(key, value);

               exportString += '</td>';
            }

            exportString += '</tr>';
         }

         exportString += '</table>';
         var ua = window.navigator.userAgent;
         var msie = ua.indexOf("MSIE ");
         // If Internet Explorer
         if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
         {  
            exportFrame.document.open("txt/html", "replace");
            exportFrame.document.write(exportString);
            exportFrame.document.close();
            exportFrame.focus();
            sa = exportFrame.document.execCommand("SaveAs", true, "participant_list.xls");
         // If Internet Explorer Edge
         } else if (navigator.userAgent.match(/Edge\/\d+/)) {
            var blob = new Blob(
               ['\ufeff', exportString],
               { encoding: 'utf8', type: 'application/vnd.ms-excel;charset_ISO-8859-1' }
            );
            navigator.msSaveOrOpenBlob( blob, "participant_list.xls" );
         } else {
            var uri = 'data:application/vnd.ms-excel,';
            var exportData = escape(exportString);
            var aTag = document.createElement('a');
            var myFileName = 'participant_list.xls';
            aTag.download = myFileName;
            aTag.setAttribute('href', uri + exportData);
            aTag.appendChild(document.createTextNode(myFileName));
            // document.getElementById('system_constants').appendChild(aTag);
            aTag.click();
         }
         },
         isEven(number) {
            number = Number(number);
            return number === 0 || !!(number && !(number%2));
            },
         getFormatedPropertyValue(propertyName, propertyValue) {
            if (propertyName == 'document_issuing_country_id' || propertyName == 'nationality') {
               return this.countryName(propertyValue).name;
            }

            if((propertyName == 'is_vaccinated' || propertyName == 'is_healthy') && (propertyValue === false)){
               return 'unbekannt';
            }

            if (propertyName == 'document_type') {

               switch(propertyValue) {
               case 1:
                  return this.$t('passport');
                  break;
               case 2:
                  return this.$t('personnel_pass');
                  break;
               case 3:
                  return this.$t('other');
                  break;
               default:
                  return '';
                  break;
               }
            }

            switch(propertyValue) {
               case 'm':
                  return this.$t('male');
                  break;
               case 'f':
                  return this.$t('female');
                  break;
               case 'o':
                  return this.$t('gender_other');
                  break;
               case 1:
                  return this.$t('yes');
                  break;
               case 0:
               case false:
                  return this.$t('no');
                  break;
               case '0000-00-00':
                  return '';
                  break;
               default:
                  return propertyValue;
            }
            },
         countryName(countryId) {
            return _.find(this.countries, (country) => {
                  return country.id == countryId;
            });
         },
      }
   }
</script>

<style lang="less">
   .b-header-participant-btn {
      font-size: calc((33 / var(--base-value)) * 1rem);
      color: var(--color-red);
      margin: 0 10px;
      cursor: pointer;

      @media only screen and (max-width : @breakpoint-md) {
         font-size: calc((25 / var(--base-value)) * 1rem);
      }
   }
</style>
