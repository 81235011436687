<template v-if="accessGranted">
   <PrintViewAction />
   <ParticipantListActions />
</template>

<script>
   import { mapState } from 'vuex';
   import PrintViewAction from '@/components/shared/header/actions/PrintViewAction.vue';
   import ParticipantListActions from '@/components/shared/header/actions/ParticipantListActions.vue';

   export default {
      components: {
         PrintViewAction,
         ParticipantListActions,
      },
      computed: {
         ...mapState([
            'accessGranted'
         ]),
      },
   }
</script>
