import participantService from '../../services/participants.js';
import { error as errorMessage, message } from '../../constants/toastMessages.js';

export default {

   state: () => ({

      participants: [],
      participantsForSubmition: [],
      submitedTableCount: 0,
      submitParticipants: false,
      unSavedChanges: false,

   }),

   getters: {

      missingAdultsCount: (state, getters, rootState) => {
         const completedAdults = getters.completedParticipants.filter(p => !p.is_student);
         return rootState.groupStructure.adultsTotal - completedAdults.length;
      },

      missingKidsCount: (state, getters, rootState) => {
         const completedKids = getters.completedParticipants.filter(p => p.is_student);
         return rootState.groupStructure.kidsTotal - completedKids.length;
      },

      completedParticipants: (state, getters, rootState) =>  {
         return state.participants.filter(p => {
            let basicFieldsCompleted = !!p.first_name && !!p.last_name && !!p.birth_date && !!p.gender;

            if (rootState.isPassportRequired) {

               let passportDocumentsCompleted = !!p.document_nr &&
                  !!p.document_type &&
                  !!p.document_issue_date &&
                  !!p.document_expiration_date &&
                  !!p.document_issuing_country_id;

               return basicFieldsCompleted && passportDocumentsCompleted;
            }

            return basicFieldsCompleted;
         });
      },

      incompleteParticipantsCount: (state, getters) => getters.totalPax - getters.completedParticipants.length,

      registeredAdults: state => state.participants.filter( participant => !participant.is_student),

      registeredKids: state => state.participants.filter( participant => participant.is_student),

   },

   actions: {
      submitParticipants({ state, commit, dispatch, rootState }, participants) {

         commit('setParticipantsForSubmition', participants);
         commit('setSubmitedTableCount', state.submitedTableCount + 1);

         let numberOfTableRequiredToSubmit = rootState.isGroupTrip ? 1 : 2 ;

         if (state.submitedTableCount == numberOfTableRequiredToSubmit) {
            dispatch('storeParticipants');
         }
      },

      fetchParticipantListData({ commit }) {
         return participantService.fetch()
            .then(participants => commit('setParticipantListData', { participants }))
            .catch(error => console.log(error));
      },

      storeParticipants({ state, dispatch, commit }) {

         // if (!state.participantsForSubmition.length) {
         //    commit('setSubmitParticipantsFlag', false);
         //    return;
         // }

         commit('setPageLoading', true);

         participantService.store({ participants: state.participantsForSubmition })
            .then(() => dispatch('fetchParticipantListData'))
            .then(() => dispatch('displayMessage', message.PARTICIPANT_STORE_SUCCESS))
            .catch(error => {
               dispatch('displayError', errorMessage.PARTICIPANT_STORE_FAIL)
               console.log(error);
            })
            .finally(() => {
               commit('setSubmitParticipantsFlag', false);
               commit('resetParticipantsForSubmition');
               commit('setSubmitedTableCount', 0);
               commit('setPageLoading', false);
         });
      },
   },

   mutations: {
      setParticipantsForSubmition(state, participants) {
         state.participantsForSubmition = [...state.participantsForSubmition, ...participants];
      },

      resetParticipantsForSubmition(state) {
         state.participantsForSubmition = [];
      },

      setParticipantListData(state, data) {
         state.participants  = data.participants;
      },

      setSubmitedTableCount(state, value) {
         state.submitedTableCount  = value;
      },

      setUnsavedChanges(state, value) {
         state.unSavedChanges = value;
      },

      setSubmitParticipantsFlag(state, value) {
         state.submitParticipants = value;
      },
   },

}