import { mapMutations, mapGetters } from 'vuex';
import routeNames from '@/router/routeNames.js';

export default {

   computed: {
      ...mapGetters([
         'accessToken',
      ]),
      onDashboardPage() {
         return this.$route.name == routeNames.DASHBOARD;
      },
      onTripDetailsPage() {
         return this.$route.name == routeNames.TRIP_DETAILS;
      },
      onCommunicationPage() {
         return this.$route.name == routeNames.COMMUNICATION;
      },
      onParticipantListPage() {
         return this.$route.name == routeNames.PARTICIPANT_LIST;
      },
   },
   methods: {
      ...mapMutations([
         'togglePageLoading',
      ]),
      goBack() {
         this.$router.back();
      },
      goToHome() {
         this.goTo(routeNames.DASHBOARD);
      },
      goToDashboardSection() {
         this.goTo(routeNames.DASHBOARD);
      },
      goToParticipantListSection() {
         this.goTo(routeNames.PARTICIPANT_LIST);
      },
      goToTripDetailsSection() {
         this.goTo(routeNames.TRIP_DETAILS);
      },
      goToCommunicationSection() {
         this.goTo(routeNames.COMMUNICATION);
      },
      goToPrintView() {
         this.goTo(routeNames.PRINT_VIEW);
      },
      goToParticipantsPrintView() {
         this.goTo(routeNames.PARTICIPANTS_PRINT);
      },
      goTo(routeName, queryStringParams = null) {
         if(routeName == this.$route.name) {
            return;
         }

         let routeData = { name: routeName };
         let accessToken = this.accessToken;
         if (accessToken) {
            routeData.params = { accessToken };
         }
         if (queryStringParams) {
            routeData.query = queryStringParams;
         }
         if(routeName == routeNames.PARTICIPANTS_PRINT || routeName == routeNames.PRINT_VIEW) {
            let routeData2 = this.$router.resolve(routeData);
            this.togglePageLoading();
            window.open(routeData2.href, '_blank');
            this.togglePageLoading();
         } else {
            this.togglePageLoading();
            this.$router.push(routeData);
         }
      },
   }
};