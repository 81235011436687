<template>
   <div class="b-program-search">
      <AutoComplete
         v-model="searchTerm"
         :suggestions="filteredPrograms"
         field="title"
         loadingIcon="noIcon"
         @clear="clearSearch()"
         @complete="searchForProgram($event)"
         :placeholder="$t('search_for_programs_placeholder')"
      />

      <div v-show="haveProgramsToDisplay" class="b-program-search__results-text">
         <strong class="h-font-bold"> {{ destinationNames }} </strong>
         {{ $t("suggestions") }}
      </div>

      <div v-show="!haveProgramsToDisplay" class="b-program-search__empty-results-text">
         {{ $t("no_results_found_for", { keyword: searchTerm }) }}
      </div>
   </div>
</template>

<script>
   import { mapState, mapMutations, mapGetters } from 'vuex';
   import AutoComplete from 'primevue/autocomplete';

   export default {
      components: {
         AutoComplete,
      },
      data() {
         return {
            filteredPrograms: [],
            searchTerm: null,
         };
      },
      computed: {
         ...mapState({
            allPrograms: state => state.dashboard.suggestedPrograms,
            destinations: state => state.destinations.all,
         }),
         ...mapGetters([
            'haveProgramsToDisplay',
         ]),
         destinationNames() {
            let destinationsNames = [];
            this.destinations.forEach(destination => {
               destinationsNames.push(destination.name);
            });
            return destinationsNames.join(' & ');
         },
      },
      methods: {
         ...mapMutations([
            'setFilteredPrograms',
         ]),
         clearSearch() {
            this.setFilteredPrograms(this.allPrograms);
         },
         searchForProgram(event) {

            if (!event.query.trim().length) {
               this.setFilteredPrograms(this.allPrograms);
               return;
            }

            let filteredPrograms = this.allPrograms.filter(program => {

               let nameMatches = program.title.toLowerCase()
                  .includes(event.query.toLowerCase());

               let descriptionMatches = program.description.toLowerCase()
                  .includes(event.query.toLowerCase());

               return nameMatches || descriptionMatches;
            });

            this.setFilteredPrograms(filteredPrograms);
         }
      },
   }
</script>

<style lang="less">
   .b-program-search {
      &__results-text {
         margin: 20px 0 10px;
      }

      .p-autocomplete {
         margin-bottom: 10px;
      }
   }
</style>