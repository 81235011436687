<template>
    <div class="b-available-extra" :class="{ 'is-selected' : selected }">

        <div class="b-available-extra__header">
            <div class="b-available-extra__button" @click="onButtonClick"></div>
            <div class="b-available-extra__name">{{ item.title }}</div>

            <div v-if="isUponRequest" class="b-available-extra__price-upon-request">
                {{ $t("price_upon_request") }}
            </div>

            <div v-else class="b-available-extra__price">+ {{ item.price }}</div>
        </div>

        <div class="b-available-extra__description">
            <!-- trimmed description -->
            <Collapsible
                :showHeader="false"
                ref="trimmedDescription"
                :isCollapsed="isOriginalDescriptionVisible"
            >
                <div class="h-collapsible-target">
                    <div v-html="item.trimmedDescription"/>
                </div>
            </Collapsible>

            <!-- full description -->
            <Collapsible
                :showHeader="false"
                :isCollapsed="isShortDescriptionVisible"
                ref="fullDescription"
            >
                <div class="h-collapsible-target">
                    <div v-html="item.description"/>
                </div>
            </Collapsible>

            <span
                class="h-link"
                @click="toggleDescription"
                v-if="hasTrimmedDescription"
            >
                <template v-if="isShortDescriptionVisible">
                    {{ $t("show_full") }}
                </template>
                <template v-else>
                    {{ $t("show_less") }}
                </template>
            </span>
        </div>
    </div>
</template>

<script>
    import Collapsible from '@/components/shared/Collapsible.vue';
    import { mapState } from 'vuex';

    export default {
        components: {
            Collapsible,
        },
        props: {
            item: {
                type: Object
            }
        },
        data() {
            return {
                selected: false,
                isOriginalDescriptionVisible: true,
                isShortDescriptionVisible: false,
                hasTrimmedDescription: false,
            }
        },
        computed: {
            ...mapState({
                selectedExtras:state => state.dashboard.selectedExtras
            }),
            isUponRequest() {
                return this.item.price == 'upon request'
                    || this.item.price == 'Preis auf Anfrage'
                    || this.item.price == 'Prix sur demande'
            }
        },
        methods: {
            onButtonClick() {
                this.selected = !this.selected;

                if (!this.selected) {
                    let otherSelectedExtras = this.selectedExtras.filter(
                        extra => extra.id != this.item.id
                    );
                    this.selectedExtras = [...otherSelectedExtras];
                    return;
                }

                this.selectedExtras.push(this.item);
            },
            toggleDescription() {
                if (this.isOriginalDescriptionVisible) {
                   this.$refs.trimmedDescription.toggle();

                   setTimeout(() => {
                      this.$refs.fullDescription.toggle();
                   }, 400);

                } else {
                   this.$refs.fullDescription.toggle();

                   setTimeout(() => {
                      this.$refs.trimmedDescription.toggle();
                   }, 400);
                }

                this.isOriginalDescriptionVisible = !this.isOriginalDescriptionVisible;
                this.isShortDescriptionVisible = !this.isShortDescriptionVisible;
            }
        },
        created() {
            this.hasTrimmedDescription = !!this.item.trimmedDescription;
            this.isShortDescriptionVisible = !!this.item.trimmedDescription;
            this.isOriginalDescriptionVisible = !this.item.trimmedDescription;
        }
    };
</script>

<style lang="less">
    .b-available-extra {
        --button-size: 20px;

        padding: 20px 20px 20px calc(~"30px + var(--button-size)");
        background-color: white;
        position: relative;
        border: solid 1px transparent;
        margin-bottom: 20px;
        .transition;

        &__header {
            position: relative;
            .h-flex-row-left-center;
        }

        &__button {
            color: var(--color-ok);
            font-size: var(--button-size);
            position: absolute;
            top: 8px;
            left: calc(~"var(--button-size) * -2");
            cursor: pointer;
            .icon(cross);
            .transform(rotate(45deg));
            .transition;
        }

        &__name {
            .h-font-bold;
            .flex-grow(1);
        }

        &__price {
            font-size: calc((19 / var(--base-value)) * 1rem);
            .h-font-bold;
            .flex-shrink(0);
        }

        &__price-upon-request {
            font-size: var(--base);
            margin-left: 10px;
            .h-font-bold;
            .flex-shrink(0);
        }

        &__description {
            margin-top: 5px;
        }

        &.is-selected {
            border-color: var(--color-ok);
            background-color: hsl(var(--color-green-hs) 93%);

            .b-available-extra__button {
                color: var(--color-not-ok);
                .transform(rotate(0));
            }
        }
    }
</style>