import api from  './api.js';

function addProgram(payload) {
   return api.post('rds/programs/add', payload);
}

function fetchData() {
   return api.get('rds/dashboard/data');
}

function fetchSuggestedPrograms() {
   return api.get('rds/programs');
}

function fetchTimelineData() {
   return api.get('rds/timeline');
}

function requestNewOffer(payload) {
   return api.post('rds/offers/rebook', payload);
}

export default {
   addProgram,
   fetchData,
   fetchSuggestedPrograms,
   fetchTimelineData,
   requestNewOffer,
};